import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/slices'

import { AccessControlManager } from './list/AccessControlListUsers.screen'

export enum UserAccessTab {
  USER = 'USER',
  ROLE = 'ROLE'
}

type State = {
  managerData: AccessControlManager | null
}

const initialState: State = {
  managerData: null
}

const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    setManagerData(
      state,
      action: PayloadAction<{ data: AccessControlManager }>
    ) {
      state.managerData = action.payload.data
    }
  }
})

export const managerDataSelector = (state: RootState) => {
  return state.accessControlReducer.managerData
}

export const { name, actions, reducer } = accessControlSlice
