import GENERAL_SERVICING from './general-servicing.png'
import CHEMICAL_WASH from './chemical-wash.png'
import AIRCON_WALL from './AIRCON-WALL.png'
import AIRCON_CASSETE from './AIRCON-CASSETE-CEILING.png'
import AIRCON_DUCTED from './AIRCON-DUCTED.png'
import AIRCON_PORTABLE from './AIRCON-PORTABLE.png'
import AIRCON_WINDOW from './AIRCON-WINDOW.png'

export default {
  GENERAL_SERVICING,
  CHEMICAL_WASH,
  AIRCON_WALL,
  AIRCON_CASSETE,
  AIRCON_DUCTED,
  AIRCON_PORTABLE,
  AIRCON_WINDOW
}
