import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useUrlQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export const useQueryParam = (
  key: string,
  defaultVal: string
): [string, (newVal: string) => void] => {
  const initialQuery = useUrlQuery()
  const [, setQuery] = useState(() => {
    return initialQuery.get(key) ?? defaultVal
  })

  const urlQuery = new URLSearchParams(window.location.search)
  const param = urlQuery.get(key) ?? defaultVal
  const updateUrl = (newVal: string) => {
    setQuery(newVal)

    if (newVal.trim() !== '') {
      urlQuery.set(key, newVal)
    } else {
      urlQuery.delete(key)
    }

    // This check is necessary if using the hook with Gatsby
    if (typeof window !== 'undefined') {
      const { protocol, pathname, host } = window.location
      const queryString = urlQuery.toString()
      const newUrl = `${protocol}//${host}${pathname}${
        queryString ? `?${queryString}` : ''
      }`
      window.history.pushState({}, '', newUrl)
    }
  }

  return [param, updateUrl]
}

export function useOnScreen(ref): boolean {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

export const useCountdown: (
  total: number,
  ms?: number
) => {
  counter: number
  start(): void
  pause(): void
  reset(value?: number): void
} = (total: number, ms = 1000) => {
  const [counter, setCountDown] = useState(total)
  const [startCountDown, setStartCountDown] = useState(false)
  // Store the created interval
  const intervalId = useRef<any>()
  const start: () => void = () => setStartCountDown(true)
  const pause: () => void = () => setStartCountDown(false)
  const reset: (value?: number) => void = (value?: number) => {
    clearInterval(intervalId.current)
    setStartCountDown(false)
    setCountDown(value ?? total)
  }

  useEffect(() => {
    intervalId.current = setInterval(() => {
      startCountDown && counter > 0 && setCountDown((counter) => counter - 1)
    }, ms)
    // Clear interval when count to zero
    if (counter === 0) clearInterval(intervalId.current)
    // Clear interval when unmount
    return () => clearInterval(intervalId.current)
  }, [startCountDown, counter, ms])

  return { counter, start, pause, reset }
}
