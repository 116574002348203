import { createSlice } from '@reduxjs/toolkit'
import { CleanerData } from 'ts/types/Cleaner'

import { RootState } from '../../redux/slices'

type State = {
  modal?: boolean
  selectedCleaner: CleanerData | null
  cleanerUpdated: boolean
  startDate: string
  endDate: string
}

export const initialState: State = {
  selectedCleaner: null,
  cleanerUpdated: false,
  startDate: '',
  endDate: ''
}

const jobDetailsSlice = createSlice({
  name: 'jobDetails',
  initialState,
  reducers: {
    showModal(state) {
      state.modal = true
    },
    hideModal(state) {
      state.modal = false
    },
    setSelectedCleaner(
      state,
      { payload: { cleaner } }: { payload: { cleaner: CleanerData | null } }
    ) {
      state.selectedCleaner = cleaner
    },
    setCleanerUpdated(
      state,
      { payload: { cleanerUpdated } }: { payload: { cleanerUpdated: boolean } }
    ) {
      state.cleanerUpdated = cleanerUpdated
    },
    setStartDate(
      state,
      { payload: { startDate } }: { payload: { startDate: string } }
    ) {
      state.startDate = startDate
    },
    setEndDate(
      state,
      { payload: { endDate } }: { payload: { endDate: string } }
    ) {
      state.endDate = endDate
    }
  }
})

export const selectedCleanerSelector = (state: RootState) =>
  state.jobDetailsReducer.selectedCleaner

export const cleanerUpdatedSelector = (state: RootState) =>
  state.jobDetailsReducer.cleanerUpdated

export const endDateSelector = (state: RootState) =>
  state.jobDetailsReducer.endDate

export const startDateSelector = (state: RootState) =>
  state.jobDetailsReducer.startDate

export const jobDetailsModalSelector = (
  state: RootState
): boolean | undefined => state.jobDetailsReducer.modal

const { name, actions, reducer } = jobDetailsSlice
export { name, actions, reducer, actions as jobDetailsActions }
