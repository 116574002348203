import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CleanerData } from 'ts/types/Cleaner'

import { RootState } from '../../redux/slices'

type FilterStatus = {
  status: string
  packageCode: string
}

type SearchPackageRequest = {
  term: string
}

type State = {
  selectedPackageRequestId: string | null
  selectedPackageRequestDetails: PackageRequestType | null
  newPackageRequestId: string | null
  searchTerm: string
  requestModal: boolean
  assignModal: boolean
  deleteModal: boolean
  confirmModal: boolean
  rejectModal: boolean
  statusFilter: string
  packageCodeFilter: string
  selectedCleaner: CleanerData | null
  updateStatus: string | null
}

export const initialState: State = {
  selectedPackageRequestId: null,
  selectedPackageRequestDetails: null,
  newPackageRequestId: null,
  searchTerm: '',
  requestModal: false,
  assignModal: false,
  deleteModal: false,
  confirmModal: false,
  rejectModal: false,
  statusFilter: '',
  packageCodeFilter: '',
  selectedCleaner: null,
  updateStatus: null
}

export interface PackageRequestType {
  id: string
  clientName: string
  clientId: string
  postalCode: string
  packageCode: string
  startDate: string
  status: string
  notes: string | null
  jobType: string
  schedules: string[]
}

const packageRequestSlice = createSlice({
  name: 'packageRequest',
  initialState,
  reducers: {
    showRequestModal(state) {
      state.requestModal = true
    },

    hideRequestModal(state) {
      state.requestModal = false
    },

    showAssignModal(state) {
      state.assignModal = true
    },

    hideAssignModal(state) {
      state.assignModal = false
    },

    showDeleteModal(state) {
      state.deleteModal = true
    },

    hideDeleteModal(state) {
      state.deleteModal = false
    },

    showConfirmModal(state) {
      state.confirmModal = true
    },

    hideConfirmModal(state) {
      state.confirmModal = false
    },

    showRejectModal(state) {
      state.rejectModal = true
    },

    hideRejectModal(state) {
      state.rejectModal = false
    },

    searchPackageRequest(state, action: PayloadAction<SearchPackageRequest>) {
      const { term } = action.payload
      state.searchTerm = term
    },

    filterStatus(state, action: PayloadAction<FilterStatus>) {
      const { status, packageCode } = action.payload

      state.statusFilter = status
      state.packageCodeFilter = packageCode
    },

    setSelectedPackageRequestId(
      state,
      {
        payload: { packageRequestId }
      }: { payload: { packageRequestId: string } }
    ) {
      state.selectedPackageRequestId = packageRequestId
    },

    setSelectedPackageRequestDetails(
      state,
      { payload: { data } }: { payload: { data: PackageRequestType | null } }
    ) {
      state.selectedPackageRequestDetails = data
    },

    setNewPackageRequestId(
      state,
      {
        payload: { packageRequestId }
      }: { payload: { packageRequestId: string } }
    ) {
      state.newPackageRequestId = packageRequestId
    },

    setSelectedCleaner(
      state,
      { payload: { cleaner } }: { payload: { cleaner: CleanerData | null } }
    ) {
      state.selectedCleaner = cleaner
    },

    setUpdateStatus(
      state,
      { payload: { status } }: { payload: { status: string } }
    ) {
      state.updateStatus = status
    },

    clearUpdateStatus(state) {
      state.updateStatus = null
    }
  }
})

export const selectedPackageRequestSelector = (state: RootState) =>
  state.packageRequestReducer.selectedPackageRequestId

export const selectedPackageRequestDetailsSelector = (state: RootState) =>
  state.packageRequestReducer.selectedPackageRequestDetails

export const newPackageRequestSelector = (state: RootState) =>
  state.packageRequestReducer.newPackageRequestId

export const searchTermSelector = (state: RootState) =>
  state.packageRequestReducer.searchTerm

export const requestModalSelector = (state: RootState) =>
  state.packageRequestReducer.requestModal

export const assignModalSelector = (state: RootState) =>
  state.packageRequestReducer.assignModal

export const deleteModalSelector = (state: RootState) =>
  state.packageRequestReducer.deleteModal

export const confirmModalSelector = (state: RootState) =>
  state.packageRequestReducer.confirmModal

export const rejectModalSelector = (state: RootState) =>
  state.packageRequestReducer.rejectModal

export const statusFilterSelector = (state: RootState) =>
  state.packageRequestReducer.statusFilter

export const packageCodeFilterSelector = (state: RootState) =>
  state.packageRequestReducer.packageCodeFilter

export const selectedCleanerSelector = (state: RootState) =>
  state.packageRequestReducer.selectedCleaner

export const updateStatusSelector = (state: RootState) =>
  state.packageRequestReducer.updateStatus

export const { name, actions, reducer } = packageRequestSlice
