import { Schedule } from '@luce/ui-kit/components/accordion/AccordionScheduleDay'
import {
  ContractTypeEnum,
  WorkerContractDayTypeEnum,
  WorkerContractWeekTypeEnum
} from '__generated__/globalTypes'
import {
  Job,
  WorkerContractDays,
  WorkerContractDetails,
  WorkerContractDetailsOption
} from 'ts/types/Cleaner'

import { shortDayIndex } from 'ts/types/Days'

import { CustomTime } from './cleanerContract.slice'

export const START_WORKING_TIME = '08:00'
export const END_WORKING_TIME = '22:00'

export const TECHNICIAN_CONTRACT_NAMES = [
  'Burmese Aircon Technician',
  'Malaysian Non SPM Holder Aircon Technician',
  'Malaysian SPM Holder Aircon Technician',
  'Replacement Office Cleaner',
  'Chinese Handyman Technician'
]

export const BABYSITTER_CONTRACT_NAMES = ['Burmese Baby Sitter']

export const ContractTypeMapping: { [key in ContractTypeEnum]: string } = {
  [ContractTypeEnum.HOURLY]: 'Hourly',
  [ContractTypeEnum.FULL_TIME]: 'Full Time',
  [ContractTypeEnum.CASUAL]: 'Casual',
  [ContractTypeEnum.CONTRACT]: 'Contract'
}

export const WeekTypeMapping: {
  [key in WorkerContractWeekTypeEnum]: string
} = {
  [WorkerContractWeekTypeEnum.ALL]: 'All Week',
  [WorkerContractWeekTypeEnum.EVEN]: 'Even Week',
  [WorkerContractWeekTypeEnum.ODD]: 'Odd Week'
}

export function createSchedule(
  workerContractDays: WorkerContractDays[]
): Schedule[] {
  const days = shortDayIndex()
  return workerContractDays?.map((workerContractDay) => {
    return {
      day: days[workerContractDay.day],
      startTime: workerContractDay.startTime,
      endTime: workerContractDay.endTime
    }
  })
}

export const initialCustomTime = (
  workingDays: WorkerContractDays[] = []
): CustomTime[] => {
  const workerSchedule: CustomTime[] = [
    { startTime: null, endTime: null, weekType: null },
    { startTime: null, endTime: null, weekType: null },
    { startTime: null, endTime: null, weekType: null },
    { startTime: null, endTime: null, weekType: null },
    { startTime: null, endTime: null, weekType: null },
    { startTime: null, endTime: null, weekType: null },
    { startTime: null, endTime: null, weekType: null }
  ]

  workingDays.forEach((workingDay) => {
    workerSchedule[workingDay.day].endTime = workingDay.endTime
    workerSchedule[workingDay.day].startTime = workingDay.startTime
    if (workingDay.weekType) {
      workerSchedule[workingDay.day].weekType = workingDay.weekType
    }
  })

  return workerSchedule
}

export const getWorkingDays = (
  workerContractDays: WorkerContractDays[],
  dayType: WorkerContractDayTypeEnum
): WorkerContractDays[] => {
  return workerContractDays.filter((days) => days.dayType === dayType)
}

const buildWorkingDaysFromJob = (job: Job): WorkerContractDays[] => {
  const findTaskByDay = (day: number) =>
    job.tasks.find((task) => task.day === day)

  return new Array(7).fill(0).map((_, day) => {
    const task = findTaskByDay(day)
    return {
      id: '',
      day,
      restDay: !task?.id,
      startTime: task?.startTime || START_WORKING_TIME,
      endTime: task?.endTime || END_WORKING_TIME,
      weekType: WorkerContractWeekTypeEnum.ALL,
      dayType: WorkerContractDayTypeEnum.REGULAR_WORKING_DAY
    }
  })
}

export const getWorkingDaysFromJob = (
  job: Job,
  dayType: WorkerContractDayTypeEnum
): WorkerContractDays[] => {
  const workingDays = buildWorkingDaysFromJob(job)

  return getWorkingDays(workingDays, dayType)
}

export const isFullWeek = (workerContractDays: CustomTime[]): boolean => {
  return workerContractDays.every(
    (workingDay) => workingDay.startTime && workingDay.endTime
  )
}

export const hasRemainingDays = (
  workerContractDays: CustomTime[],
  selectedDays: number[]
): boolean => {
  if (isFullWeek(workerContractDays)) {
    return false
  }

  const selectedWorkerContractDays = workerContractDays
    .map((day, dayNumber) => {
      if (day.startTime && day.endTime) {
        return dayNumber
      }
    })
    .filter(Number.isInteger)

  const combinedDays = selectedWorkerContractDays.concat(selectedDays)

  if (combinedDays.length === 7) {
    return false
  }

  return true
}

export const workerDayTypeBadgeMapping: Record<
  WorkerContractDayTypeEnum,
  string
> = {
  [WorkerContractDayTypeEnum.NON_WORKING_OFF_DAY]: 'OFF',
  [WorkerContractDayTypeEnum.REGULAR_WORKING_DAY]: 'RWD',
  [WorkerContractDayTypeEnum.STANDARD_WORKING_OFF_DAY]: 'SWO',
  [WorkerContractDayTypeEnum.ENHANCED_WORKING_OFF_DAY]: 'EWD'
}

export const workerRestDayMapping: Record<WorkerContractDayTypeEnum, boolean> =
  {
    [WorkerContractDayTypeEnum.NON_WORKING_OFF_DAY]: true,
    [WorkerContractDayTypeEnum.ENHANCED_WORKING_OFF_DAY]: true,
    [WorkerContractDayTypeEnum.STANDARD_WORKING_OFF_DAY]: true,
    [WorkerContractDayTypeEnum.REGULAR_WORKING_DAY]: false
  }

export const getWorkingDaysLabels = (dayType: WorkerContractDayTypeEnum) => {
  switch (dayType) {
    case WorkerContractDayTypeEnum.REGULAR_WORKING_DAY:
      return {
        title: 'Regular Working Days',
        subtitle: 'Please select Regular Working Days'
      }
    case WorkerContractDayTypeEnum.STANDARD_WORKING_OFF_DAY:
      return {
        title: 'Standard Working Off Day',
        subtitle:
          'Please select Standard Working Off Day. Working on this day will be paid with Regular Working Day Rate.'
      }
    case WorkerContractDayTypeEnum.ENHANCED_WORKING_OFF_DAY:
      return {
        title: 'Enhanced Working Off Day',
        subtitle:
          'Please select Enhanced Working Off Day. Working on this day will be paid with Special Rate.'
      }

    default:
      return { title: 'Working Day', subtitle: 'Select Working Day' }
  }
}

export const mapContractDetailOptions = (
  contractDetails: WorkerContractDetails[]
): WorkerContractDetailsOption[] => {
  const workerContractDetailList = contractDetails.map((contractDetail) => ({
    label: contractDetail.contractName,
    value: contractDetail.id
  }))

  return workerContractDetailList
}

export const nonWorkingOffDay = (day: number): WorkerContractDays => ({
  startTime: START_WORKING_TIME,
  endTime: END_WORKING_TIME,
  restDay: true,
  dayType: WorkerContractDayTypeEnum.NON_WORKING_OFF_DAY,
  weekType: WorkerContractWeekTypeEnum.ALL,
  day
})
