import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/slices'

import { PromoCode } from './lib'

type State = {
  showPromoCodeModal: boolean
  selectedPromoCode: PromoCode | null
}

export const initialState: State = {
  showPromoCodeModal: false,
  selectedPromoCode: null
}

const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState,
  reducers: {
    showPromoCodeModal(state) {
      state.showPromoCodeModal = true
    },
    hidePromoCodeModal(state) {
      state.showPromoCodeModal = false
    },
    setSelectedPromoCode(state, { payload }) {
      state.selectedPromoCode = payload
    }
  }
})

export const promoCodeModalSelector = (state: RootState) =>
  state.promoCodeReducer.showPromoCodeModal

export const selectedPromoCodeSelector = (
  state: RootState
): null | PromoCode => {
  return state.promoCodeReducer.selectedPromoCode
}

export const { name, actions, reducer } = promoCodeSlice
