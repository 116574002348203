import { configureStore } from '@reduxjs/toolkit'
import { StoreEnhancer } from 'redux'
import { logger } from 'redux-logger'

import appReducer from './slices/'
// import { sentryReduxEnhancer } from 'services/sentry'

export default function configureAppStore(initialState = {}) {
  const middlewares = import.meta.env.VITE_REACT_APP_ENV === 'test' ? [] : [logger]

  const enhancers: StoreEnhancer[] = []
  const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(),  ...middlewares],
    preloadedState: initialState,
    devTools: import.meta.env.VITE_REACT_APP_ENV !== 'production',
    enhancers
  })

  return store
}

export const store = configureAppStore()
