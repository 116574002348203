import { LazyQueryResult } from '@apollo/client/react/types/types'
import { useLazyQuery } from '@apollo/react-hooks'
import { WorkerTipsMessageByFiltersVariables } from '__generated__/WorkerTipsMessageByFilters'
import { WorkerTipFilterInput } from '__generated__/globalTypes'

import { WorkerTipMessageQuery } from 'ts/types/WorkerTips'

import ALL_WORKER_TIPS from   '../__graphql__/WorkerTipsMessageByFilters.graphql'

type QueryResult = LazyQueryResult<
  WorkerTipMessageQuery,
  WorkerTipsMessageByFiltersVariables
>

export default function useAllWorkerTipsQuery(): [
  (filters: WorkerTipFilterInput) => void,
  QueryResult
] {
  const [allWorkerTips, result] = useLazyQuery<
    WorkerTipMessageQuery,
    WorkerTipsMessageByFiltersVariables
  >(ALL_WORKER_TIPS)

  function allWorkerTipQuery(filters: WorkerTipFilterInput): void {
    allWorkerTips({
      variables: {
        filters
      }
    })
  }

  return [allWorkerTipQuery, result]
}

export { useAllWorkerTipsQuery }
