import { useMutation } from '@apollo/react-hooks'
import Backdrop from '@luce/ui-kit/components/atom/Backdrop'
import CircularProgress from '@luce/ui-kit/components/atom/CircularProgress'
import { signOut } from '__generated__/signOut'
import Session, {
  UserSession,
  userSessionKey
} from 'context/AuthContext/UserSession'

import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  getJwtFromRefreshToken,
  setJWT,
  setLogoutCallback
} from 'services/apollo'
import { preventSilentSignin } from 'utils/helpers'
import SIGNOUT_MUTATION from './__graphql__/SignOutMutation.graphql'

export interface User {
  id: string
  firstName: string
  lastName: string
  avatarUrl?: string
}

export interface AuthInterface {
  user?: User | null | undefined
  logout(): void
  loggedIn(user: User, jwt: string, jwtExpiry: number): void
  loadingSession: boolean
  isSigningOut: boolean
}

const restoredSession = Session.currentSession()

const AuthContext = createContext<AuthInterface | undefined>(undefined)

type Props = {
  children?: ReactNode
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const [session, setSession] = useState<UserSession>(restoredSession)
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [signoutMutation] = useMutation<signOut>(SIGNOUT_MUTATION)

  const clearSession = () => {
    const session = Session.logout()
    setSession(session)
  }

  const logout = useCallback(async () => {
    preventSilentSignin()
    setIsSigningOut(true)
    try {
      await signoutMutation()
    } catch (err) {
      console.log(err)
    } finally {
      setJWT(undefined)
      clearSession()
      setIsSigningOut(false)
    }
  }, [])

  const loggedIn = useCallback((user: User, jwt: string, jwtExpiry: number) => {
    setJWT(jwt)
    const session = Session.login(user, jwt, jwtExpiry)
    console.log('Logged In. Session = ', session)
    setSession(session)
  }, [])

  const checkSession = async (session: UserSession) => {
    if (session.user) {
      try {
        const now = new Date().valueOf()
        if (session.jwtToken && session.jwtExpiry && session.jwtExpiry > now) {
          console.log('* Set network authorization token based on Session')
          setJWT(session.jwtToken)
        } else {
          await getJwtFromRefreshToken()
        }
      } catch (err) {
        if (err.status === 403 || err.status === 404) {
          const session = Session.logout()
          setSession(session)
        }
      }
    }
    setIsLoading(false)
  }

  const user = session?.user

  const onStorage = useCallback(
    (e: StorageEvent) => {
      if (e.key === userSessionKey) {
        console.log('oldValue', e.oldValue)
        console.log('newValue', e.newValue)
        if (e.oldValue && !e.newValue) {
          console.log('User logged out on another tab')
          setJWT(undefined)
          const session = Session.currentSession()
          setSession(session)
        } else if (!e.oldValue && e.newValue) {
          console.log('User logged in on another tab')
        }
      }
    },
    [user]
  )

  useEffect(() => {
    checkSession(session)
    setLogoutCallback(clearSession)
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loadingSession: isLoading,
        user: session.user,
        logout,
        loggedIn,
        isSigningOut
      }}
    >
      {children}
      <Backdrop open={isSigningOut || isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  )
}

export default AuthContext
