import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  mapVisitsByWorker,
  mapLeavesByWorker,
  generateOffdayCell,
  mapTasksByWorker
} from 'components/dashboard/lib'
import { CleanerData, WorkerSchedulerTask } from 'ts/types/Cleaner'
import {
  WorkerSchedulerVisit,
  WorkerSchedulerLeave
} from 'ts/types/WorkerSchedule'

import { RootState } from '../../redux/slices'
import {
  currentDateSelector,
  setQueriedCleanersAction,
  setVisitStatusFilterAction
} from './schedulerCleaners.slice'
import {
  CleanerLeavesGroup,
  CleanerVisitsGroup,
  OffdayCellData,
  WorkerRestDaysGroup,
  WorkerTasksGroup
} from './weeklyScheduler.slice'

type SetCleanerVisits = {
  visits: WorkerSchedulerVisit[]
  workerId: string[]
}
type SetCleanerLeaves = {
  leaves: WorkerSchedulerLeave[]
  workerId: string[]
}
type SetWorkerTasks = {
  workerId: string[]
  tasks: WorkerSchedulerTask[]
}
type State = {
  workers: CleanerData[]
  workerVisits: CleanerVisitsGroup
  workerLeaves: CleanerLeavesGroup
  workerRestDays: WorkerRestDaysGroup
  workerTasks: WorkerTasksGroup
}

export const initialState: State = {
  workers: [],
  workerVisits: {},
  workerLeaves: {},
  workerRestDays: {},
  workerTasks: {}
}

const setNullSchedulerData = (workerIds) => {
  const nullWorkerVisits = {}
  const nullWorkerLeaves = {}
  const nullWorkerRestDays = {}
  const nullWorkerTasks = {}

  workerIds.forEach((id) => {
    nullWorkerVisits[id] = null
    nullWorkerLeaves[id] = null
    nullWorkerRestDays[id] = null
    nullWorkerTasks[id] = null
  })

  return {
    nullWorkerVisits,
    nullWorkerLeaves,
    nullWorkerRestDays,
    nullWorkerTasks
  }
}

const dailySchedulerSlice = createSlice({
  name: 'dailyScheduler',
  initialState,
  reducers: {
    setCleanerVisits(state, action: PayloadAction<SetCleanerVisits>) {
      const { workerVisits } = state
      const { visits, workerId } = action.payload
      if (visits.length) {
        mapVisitsByWorker(visits).forEach((visit) => {
          const workerId = visit.ownerId.toString()
          const currentWorkerVisits = workerVisits[workerId] || []
          const currentWorkerVisitIds = currentWorkerVisits.map(
            ({ visitId }) => visitId
          )
          // prevent duplicates visit
          if (!currentWorkerVisitIds.includes(visit.visitId)) {
            currentWorkerVisits.push(visit)
          }

          workerVisits[workerId] = currentWorkerVisits
        })
      }
      workerId.forEach((id) => {
        workerVisits[id] = workerVisits[id] || []
      })

      state.workerVisits = workerVisits
    },
    setCleanerLeaves(state, action: PayloadAction<SetCleanerLeaves>) {
      const { workerLeaves } = state
      const { leaves, workerId } = action.payload
      if (leaves.length) {
        mapLeavesByWorker(leaves).forEach((leave) => {
          const workerId = leave.ownerId.toString()
          const currentWorkerLeaves = workerLeaves[workerId] || []
          const currentWorkerLeaveIds = currentWorkerLeaves.map(
            ({ leaveId }) => leaveId
          )
          // prevent duplicates leave
          if (!currentWorkerLeaveIds.includes(leave.leaveId)) {
            currentWorkerLeaves.push(leave)
          }

          workerLeaves[workerId] = currentWorkerLeaves
        })
      }
      workerId.forEach((id) => {
        workerLeaves[id] = workerLeaves[id] || []
      })

      state.workerLeaves = workerLeaves
    },
    setCleanerRestDays(
      state,
      { payload }: PayloadAction<{ restDays: WorkerRestDaysGroup }>
    ) {
      state.workerRestDays = { ...state.workerRestDays, ...payload.restDays }
    },
    setWorkerTasks(state, action: PayloadAction<SetWorkerTasks>) {
      const { workerTasks } = state
      const { tasks, workerId } = action.payload
      if (tasks.length) {
        mapTasksByWorker(tasks).forEach((task) => {
          const workerId = task.ownerId.toString()
          const currentWorkerTasks = workerTasks[workerId] || []
          const currentWorkerTaskIds = currentWorkerTasks.map(
            ({ taskId }) => taskId
          )
          // prevent duplicates
          if (!currentWorkerTaskIds.includes(task.taskId)) {
            currentWorkerTasks.push(task)
          }

          workerTasks[workerId] = currentWorkerTasks
        })
      }
      workerId.forEach((id) => {
        workerTasks[id] = workerTasks[id] || []
      })

      state.workerTasks = workerTasks
    },
    addWorker(
      state,
      { payload: { worker } }: PayloadAction<{ worker: CleanerData }>
    ) {
      const worker_ids = state.workers.map((worker) => worker.id)
      if (!worker_ids.includes(worker.id)) {
        state.workers.push(worker)
      }
    },
    addWorkerByManager(
      state,
      action: PayloadAction<{ workers: CleanerData[] }>
    ) {
      const { workers } = action.payload

      const workerIds = workers.map((worker) => worker.id)
      const {
        nullWorkerVisits,
        nullWorkerLeaves,
        nullWorkerRestDays,
        nullWorkerTasks
      } = setNullSchedulerData(workerIds)

      // init new worker visits and leaves
      state.workers = workers
      state.workerVisits = nullWorkerVisits
      state.workerLeaves = nullWorkerLeaves
      state.workerRestDays = nullWorkerRestDays
      state.workerTasks = nullWorkerTasks
    },
    clearSchedulerData(state) {
      state.workers = []
      state.workerVisits = {}
      state.workerLeaves = {}
      state.workerRestDays = {}
      state.workerTasks = {}
    },
    cleanWorkerData(state) {
      const existingWorkerIds = Object.keys(state.workerVisits)
      const {
        nullWorkerVisits,
        nullWorkerLeaves,
        nullWorkerRestDays,
        nullWorkerTasks
      } = setNullSchedulerData(existingWorkerIds)
      state.workerVisits = nullWorkerVisits
      state.workerLeaves = nullWorkerLeaves
      state.workerRestDays = nullWorkerRestDays
      state.workerTasks = nullWorkerTasks
    },
    removeWorker(
      state,
      { payload: { workerId } }: PayloadAction<{ workerId: string }>
    ) {
      const filterWorkers = state.workers.filter(
        (workers) => workers.id !== workerId
      )
      state.workers = filterWorkers
      delete state.workerVisits[workerId]
      delete state.workerLeaves[workerId]
      delete state.workerRestDays[workerId]
      delete state.workerTasks[workerId]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setVisitStatusFilterAction, (state) => {
        // set the cleaner visit and leave to null, to trigger get appointmentData in dashboard component
        const existingWorkerIds = Object.keys(state.workerVisits)
        const { nullWorkerVisits, nullWorkerLeaves } =
          setNullSchedulerData(existingWorkerIds)
        state.workerVisits = nullWorkerVisits
        state.workerLeaves = nullWorkerLeaves
      })
      .addCase(setQueriedCleanersAction, (state, { payload }) => {
        // init queried cleaner visit and leave to null
        const workerIds = payload.cleaners.map((cleaner) => cleaner.id)
        const { nullWorkerVisits, nullWorkerLeaves } =
          setNullSchedulerData(workerIds)

        state.workerVisits = nullWorkerVisits
        state.workerLeaves = nullWorkerLeaves
      })
  }
})

export const dailyWorkersSelector = (state: RootState) =>
  state.dailySchedulerReducer.workers

export const workerVisitsSelector = (state: RootState): CleanerVisitsGroup => {
  return state.dailySchedulerReducer.workerVisits
}
export const workerLeaveSelector = (state: RootState): CleanerLeavesGroup => {
  return state.dailySchedulerReducer.workerLeaves
}
export const workerTasksSelector = (state: RootState): WorkerTasksGroup => {
  return state.dailySchedulerReducer.workerTasks
}

export const workerOffDaysSelector = createSelector(
  [
    (state: RootState) => state.dailySchedulerReducer.workerRestDays,
    currentDateSelector
  ],
  (workerRestDays, currentDate): OffdayCellData[] => {
    const allWorkerRestDays = Object.values(workerRestDays).flatMap(
      (restDay) => restDay ?? []
    )

    const startDate = new Date(currentDate)

    return generateOffdayCell(allWorkerRestDays, [startDate])
  }
)

const { name, actions, reducer } = dailySchedulerSlice
export { name, actions, reducer, actions as dailySchedulerActions }
