import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setPackageDataAction } from 'components/package/package.slice'
import { mapJobPackagetoTemporary } from 'utils/mapper/jobMapper'

import { RootState } from '../../redux/slices'
import { JobPackage, TemporaryPackageJobs } from '../../ts/types/Job'

type State = {
  jobs: JobPackage[]
  temporaryJobs: TemporaryPackageJobs[]
}

export const initialState: State = {
  jobs: [],
  temporaryJobs: []
}

type AddJob = {
  job: JobPackage
}

type RemoveJob = {
  id: string
}

type SetJobs = {
  jobs: JobPackage[]
}

const jobsListSlice = createSlice({
  name: 'jobsList',
  initialState,
  reducers: {
    setJobs(state, action: PayloadAction<SetJobs>) {
      state.jobs = action.payload.jobs
    },
    addJob(state, action: PayloadAction<AddJob>) {
      state.jobs.push(action.payload.job)
    },
    removeJob(state, action: PayloadAction<RemoveJob>) {
      state.jobs = state.jobs.filter(({ id }) => id !== action.payload.id)
    },
    clearJobs(state): void {
      state.jobs = []
    },
    initTemporaryJobs(state): void {
      state.temporaryJobs = state.jobs.map(mapJobPackagetoTemporary)
    },
    clearTemporaryJobs(state): void {
      state.temporaryJobs = []
    },
    addTemporaryJobs(state, action: { payload: TemporaryPackageJobs }): void {
      action.payload.identifier = Math.random().toString()
      state.temporaryJobs.push(action.payload)
    },
    removeTemporaryJobsByIndex(state, action): void {
      state.temporaryJobs.splice(action.payload, 1)
    },
    clearAllJobs(state) {
      state.jobs = []
      state.temporaryJobs = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setPackageDataAction, (state, { payload }) => {
      state.jobs = payload.packageData.jobs!
    })
  }
})

export const jobsListSelector = (state: RootState) => state.jobsListReducer.jobs

export const temporaryJobsSelector = (state: RootState) =>
  state.jobsListReducer.temporaryJobs

export const hasModifiedJobsSelector = (state: RootState) => {
  if (state.jobReducer.isEdit === false) {
    return false
  }

  if (
    state.jobsListReducer.jobs.length !==
    state.jobsListReducer.temporaryJobs.length
  ) {
    return true
  }

  const jobs = state.jobsListReducer.jobs.map(mapJobPackagetoTemporary)
  const temps = state.jobsListReducer.temporaryJobs
  const tempsids = temps.map((job) => job.identifier)

  return !jobs.every((job) => tempsids.includes(job.identifier))
}

const { name, actions, reducer } = jobsListSlice
export { name, actions, reducer, actions as jobsListActions }
