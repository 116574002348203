import BoxRow from '@luce/ui-kit/components/atom/BoxRow'
import Box from '@luce/ui-kit/components/base/Box'
import Typography from '@luce/ui-kit/components/base/Typography'
import Button from '@luce/ui-kit/components/button/Button'
import IconButton from '@luce/ui-kit/components/button/IconButton'
import Icon from '@luce/ui-kit/components/icons/Icons'
import { FormTextField } from 'luce-ui-components'
import { makeStyles, Theme } from '@luce/ui-kit/themes/ThemeProvider'
import { ServiceTypeEnum, PackageUnitTypeEnum } from '__generated__/globalTypes'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { AvailableClientAirconService } from '../booking.slice'
import Images from '../images/Images'
import { PackageUnitTypeMapping } from '../lib'
import { landscapeMobileScreen, useMediaQuery } from './MediaQueryResponsive'

interface Props {
  serviceType: AvailableClientAirconService
  inputName: string
  serviceTypeUnitPrice: Record<string, string>
  availablesUnitTypeUnits: Record<string, string[]>
}

export const UNIT_TYPES_IMAGE = {
  [PackageUnitTypeEnum.WALL]: Images.AIRCON_WALL,
  [PackageUnitTypeEnum.DUCTED]: Images.AIRCON_DUCTED,
  [PackageUnitTypeEnum.CEILING]: Images.AIRCON_CASSETE,
  [PackageUnitTypeEnum.PORTABLE]: Images.AIRCON_PORTABLE,
  [PackageUnitTypeEnum.WINDOW]: Images.AIRCON_WINDOW,
  [PackageUnitTypeEnum.ALL]: Images.AIRCON_WALL
}

export const SERVICE_TYPE_UNIT_TYPES: Record<
  AvailableClientAirconService,
  PackageUnitTypeEnum[]
> = {
  [ServiceTypeEnum.GENERAL_SERVICING]: [
    PackageUnitTypeEnum.WALL,
    PackageUnitTypeEnum.CEILING,
    PackageUnitTypeEnum.DUCTED,
    PackageUnitTypeEnum.PORTABLE,
    PackageUnitTypeEnum.WINDOW
  ],
  [ServiceTypeEnum.CHEMICAL_WASH]: [PackageUnitTypeEnum.WALL],
  [ServiceTypeEnum.JET_WASH]: [PackageUnitTypeEnum.WALL],
  [ServiceTypeEnum.CHEMICAL_OVERHAUL]: [
    PackageUnitTypeEnum.WALL,
    PackageUnitTypeEnum.CEILING
  ],
  [ServiceTypeEnum.CONDENSER_WASH]: [PackageUnitTypeEnum.ALL],
  [ServiceTypeEnum.REPAIR_DIAGNOSTIC]: [
    PackageUnitTypeEnum.WALL,
    PackageUnitTypeEnum.CEILING,
    PackageUnitTypeEnum.DUCTED,
    PackageUnitTypeEnum.PORTABLE,
    PackageUnitTypeEnum.WINDOW
  ]
}

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 42,
    '& input': {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      fontSize: 24,
      border: 'none !important',
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center'
    },
    '& fieldset': {
      borderWidth: 0
    }
  },
  buttonInput: {
    border: `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    padding: 0
  },
  buttonMore: {
    color: theme.palette.secondary.main,
    background: 'transparent'
  },
  unitImage: {
    borderRadius: theme.spacing(1),
    width: 'clamp(200px, 100%, 240px)',
    height: 'clamp(110px, 120px, 120px)',
    objectFit: 'cover'
  }
}))

const AirconServiceUnitInput: React.FC<Props> = (props) => {
  const {
    serviceType,
    inputName,
    serviceTypeUnitPrice,
    availablesUnitTypeUnits
  } = props

  const classes = useStyles()

  const [showAll, setShowAll] = useState(false)

  const {
    formState: { errors },
    getValues,
    setValue
  } = useFormContext<{
    unitTypes: Record<string, string>
  }>()

  const mobileLandscape = useMediaQuery(landscapeMobileScreen)

  const handlePlus = (unitName: string): void => {
    const currentValue = getValues<string, string>(`${inputName}.${unitName}`)
    const newVal = parseInt(currentValue) + 1
    if (!availablesUnitTypeUnits[unitName].includes(`${newVal}`)) {
      return
    }
    setValue(`${inputName}.${unitName}`, `${newVal}`)
  }

  const handleMinus = (unitName: string): void => {
    const currentValue = getValues<string, string>(`${inputName}.${unitName}`)
    const newVal = parseInt(currentValue) - 1
    if (!availablesUnitTypeUnits[unitName].includes(`${newVal}`)) {
      return
    }
    setValue(`${inputName}.${unitName}`, `${newVal}`)
  }

  return (
    <Box>
      {SERVICE_TYPE_UNIT_TYPES[serviceType].map((unit, index) => (
        <Box key={unit} hidden={index > 2 && !showAll}>
          <Typography
            color="primary"
            variant="subtitle1"
            align={mobileLandscape ? 'center' : 'left'}
          >
            {PackageUnitTypeMapping[unit]}
          </Typography>
          <BoxRow
            alignItems="center"
            direction={mobileLandscape ? 'column' : 'row'}
            mb={3}
            gap={mobileLandscape ? 3 : 6}
            mt={1.5}
          >
            <Box flex={1}>
              <img alt={unit} src={UNIT_TYPES_IMAGE[unit]} className={classes.unitImage} />
            </Box>
            <BoxRow
              flex={1}
              gap={3}
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                iconSize="large"
                color="secondary"
                iconName="MinusIcon"
                className={classes.buttonInput}
                onClick={() => handleMinus(`${serviceType}_${unit}`)}
              />
              <FormTextField
                customSize="large"
                name={`${inputName}.${serviceType}_${unit}`}
                readOnly
                className={classes.input}
                defaultValue="0"
              />
              <IconButton
                iconSize="large"
                color="secondary"
                iconName="PlusIcon"
                className={classes.buttonInput}
                onClick={() => handlePlus(`${serviceType}_${unit}`)}
              />
            </BoxRow>
            <Box flex={1}>
              {serviceTypeUnitPrice[`${serviceType}_${unit}`] && (
                <Box p={2} borderRadius={8} bgcolor="#F4FAFD">
                  <Typography
                    align="center"
                    variant="subtitle1"
                    color="primary"
                  >
                    {serviceTypeUnitPrice[`${serviceType}_${unit}`]}
                  </Typography>
                </Box>
              )}
            </Box>
          </BoxRow>
        </Box>
      ))}

      {SERVICE_TYPE_UNIT_TYPES[serviceType].length > 3 && !showAll && (
        <Button
          variants="ghost"
          color="secondary"
          fullWidth={mobileLandscape}
          className={classes.buttonMore}
          onClick={() => setShowAll((value) => !value)}
        >
          View More <Icon iconName="ArrowBottomIcon" />
        </Button>
      )}

      {errors?.[inputName] && (
        <Box mt={2}>
          <Typography variant="caption" color="error">
            {errors[inputName]?.message ?? ''}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default AirconServiceUnitInput
