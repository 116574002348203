import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EventType } from 'components/calendar/Calendar.screen'
import { JobCellProps } from 'components/calendar/cell/JobCell.screen'
import { LeaveCellProps } from 'components/calendar/cell/LeaveCell.screen'
import { OffdayCellProps } from 'components/calendar/cell/OffdayCell.screen'
import { WorkerTaskCellProps } from 'components/calendar/cell/WorkerTaskCell.screen'
import { WorkerSchedulerTask, CleanerData } from 'ts/types/Cleaner'
import {
  WorkerRestDay,
  WorkerSchedulerLeave,
  WorkerSchedulerVisit
} from 'ts/types/WorkerSchedule'
import { getDateRangeBetween, getFirstLastDateinWeek } from 'utils/date'

import { RootState } from '../../redux/slices'
import {
  mapVisitsByWorker,
  mapLeavesByWorker,
  mapTasksByWorker,
  generateOffdayCell
} from './lib'
import {
  currentDateSelector,
  INITIAL_QUERIED_CLEANER_LIMIT,
  selectedCleanerSelector,
  setQueriedCleanersAction,
  setVisitStatusFilterAction
} from './schedulerCleaners.slice'

export type VisitCellData = EventType & JobCellProps
export type LeaveCellData = EventType & LeaveCellProps
export type OffdayCellData = EventType & OffdayCellProps
export type WorkerTaskCellData = EventType & WorkerTaskCellProps

export type CleanerVisitsGroup = Record<string, VisitCellData[] | null>
export type CleanerLeavesGroup = Record<string, LeaveCellData[] | null>
export type WorkerRestDaysGroup = Record<string, WorkerRestDay[] | null>
export type WorkerTasksGroup = Record<string, WorkerTaskCellData[] | null>

type WorkerFetchWeek = Record<string, number[]>

type SetCleanerVisits = {
  visits: WorkerSchedulerVisit[]
  workerId: string[]
  weekNumber: number
}

type SetCleanerLeaves = {
  leaves: WorkerSchedulerLeave[]
  workerId: string[]
  weekNumber: number
}

type SetWorkerTasks = {
  workerId: string[]
  weekNumber: number
  tasks: WorkerSchedulerTask[]
}

type State = {
  workers: CleanerData[]
  workerFetchWeeks: WorkerFetchWeek
  workerVisits: CleanerVisitsGroup
  workerLeaves: CleanerLeavesGroup
  workerRestDays: WorkerRestDaysGroup
  workerTasks: WorkerTasksGroup
}

export const initialState: State = {
  workers: [],
  workerFetchWeeks: {},
  workerVisits: {},
  workerLeaves: {},
  workerRestDays: {},
  workerTasks: {}
}

const setNullSchedulerData = (workerIds) => {
  const nullWorkerVisits = {}
  const nullWorkerLeaves = {}
  const emptyWorkerWeeks = {}
  const nullWorkerRestDays = {}

  workerIds.forEach((id) => {
    nullWorkerVisits[id] = null
    nullWorkerLeaves[id] = null
    nullWorkerRestDays[id] = null
    emptyWorkerWeeks[id] = []
  })

  return {
    nullWorkerVisits,
    nullWorkerLeaves,
    emptyWorkerWeeks,
    nullWorkerRestDays
  }
}

const countStoredWorkerVisits = (visitGroups: CleanerVisitsGroup): number => {
  const visitsArrayNotNull = Object.values(visitGroups).filter(
    (visits) => visits !== null
  )

  return visitsArrayNotNull.length
}

const weeklySchedulerSlice = createSlice({
  name: 'weeklyScheduler',
  initialState,
  reducers: {
    cleanupOverlimitData(state) {
      const { workerVisits, workerFetchWeeks, workers, workerLeaves } = state
      // check and clean up data when stored worker visit over 20
      // to prevent memory exhausted in browser that cause performance issue
      // and might cause the browser crashed
      const currentStoredWorkerVisits = countStoredWorkerVisits(workerVisits)
      if (currentStoredWorkerVisits >= INITIAL_QUERIED_CLEANER_LIMIT) {
        workers.forEach((worker) => {
          workerVisits[worker.id] = null
          workerLeaves[worker.id] = null
          workerFetchWeeks[worker.id] = []
        })
      }
    },
    setCleanerVisits(state, action: PayloadAction<SetCleanerVisits>) {
      const { workerVisits, workerFetchWeeks } = state
      const { visits, workerId, weekNumber } = action.payload
      if (visits.length) {
        mapVisitsByWorker(visits).forEach((visit) => {
          const workerId = visit.ownerId.toString()
          const currentWorkerVisits = workerVisits[workerId] || []
          const currentWorkerVisitIds = currentWorkerVisits.map(
            ({ visitId }) => visitId
          )
          // prevent duplicates visit
          if (!currentWorkerVisitIds.includes(visit.visitId)) {
            currentWorkerVisits.push(visit)
          }

          workerVisits[workerId] = currentWorkerVisits
        })
      }
      workerId.forEach((id) => {
        workerVisits[id] = workerVisits[id] || []
        if (workerFetchWeeks[id]) {
          workerFetchWeeks[id].push(weekNumber)
        } else {
          workerFetchWeeks[id] = [weekNumber]
        }
      })

      state.workerVisits = workerVisits
    },
    setCleanerLeaves(state, action: PayloadAction<SetCleanerLeaves>) {
      const { workerLeaves, workerFetchWeeks } = state
      const { leaves, workerId, weekNumber } = action.payload
      if (leaves.length) {
        mapLeavesByWorker(leaves).forEach((leave) => {
          const workerId = leave.ownerId.toString()
          const currentWorkerLeaves = workerLeaves[workerId] || []
          const currentWorkerLeaveIds = currentWorkerLeaves.map(
            ({ leaveId }) => leaveId
          )
          // prevent duplicates leave
          if (!currentWorkerLeaveIds.includes(leave.leaveId)) {
            currentWorkerLeaves.push(leave)
          }

          workerLeaves[workerId] = currentWorkerLeaves
        })
      }
      workerId.forEach((id) => {
        workerLeaves[id] = workerLeaves[id] || []
        workerFetchWeeks[id].push(weekNumber)
      })

      state.workerLeaves = workerLeaves
    },
    setCleanerRestDays(
      state,
      { payload }: PayloadAction<{ restDays: WorkerRestDaysGroup }>
    ) {
      state.workerRestDays = { ...state.workerRestDays, ...payload.restDays }
    },
    setWorkerTasks(state, action: PayloadAction<SetWorkerTasks>) {
      const { workerTasks } = state
      const { tasks, workerId } = action.payload
      if (tasks.length) {
        mapTasksByWorker(tasks).forEach((task) => {
          const workerId = task.ownerId.toString()
          const currentWorkerTasks = workerTasks[workerId] || []
          const currentWorkerTaskIds = currentWorkerTasks.map(
            ({ taskId }) => taskId
          )
          // prevent duplicates
          if (!currentWorkerTaskIds.includes(task.taskId)) {
            currentWorkerTasks.push(task)
          }

          workerTasks[workerId] = currentWorkerTasks
        })
      }
      workerId.forEach((id) => {
        workerTasks[id] = workerTasks[id] || []
      })

      state.workerTasks = workerTasks
    },
    addWorker(
      state,
      { payload: { worker } }: PayloadAction<{ worker: CleanerData }>
    ) {
      const worker_ids = state.workers.map((worker) => worker.id)
      if (!worker_ids.includes(worker.id)) {
        state.workers.push(worker)
        state.workerFetchWeeks[worker.id] = []
        state.workerVisits[worker.id] = null
        state.workerLeaves[worker.id] = null
        state.workerRestDays[worker.id] = null
      }
    },
    addWorkerByManager(
      state,
      action: PayloadAction<{ workers: CleanerData[] }>
    ) {
      const { workers } = action.payload

      const workerIds = workers.map((worker) => worker.id)
      const {
        nullWorkerVisits,
        nullWorkerLeaves,
        emptyWorkerWeeks,
        nullWorkerRestDays
      } = setNullSchedulerData(workerIds)

      // init new worker visits and leaves
      state.workers = workers
      state.workerVisits = nullWorkerVisits
      state.workerLeaves = nullWorkerLeaves
      state.workerFetchWeeks = emptyWorkerWeeks
      state.workerRestDays = nullWorkerRestDays
    },
    clearSchedulerData(state) {
      state.workers = []
      state.workerVisits = {}
      state.workerLeaves = {}
      state.workerFetchWeeks = {}
      state.workerRestDays = {}
    },
    cleanWorkerData(state) {
      const existingWorkerIds = Object.keys(state.workerVisits)
      const {
        nullWorkerVisits,
        nullWorkerLeaves,
        emptyWorkerWeeks,
        nullWorkerRestDays
      } = setNullSchedulerData(existingWorkerIds)

      state.workerVisits = nullWorkerVisits
      state.workerLeaves = nullWorkerLeaves
      state.workerFetchWeeks = emptyWorkerWeeks
      state.workerRestDays = nullWorkerRestDays
    },
    removeWorker(
      state,
      { payload: { workerId } }: PayloadAction<{ workerId: string }>
    ) {
      const filterWorkers = state.workers.filter(
        (workers) => workers.id !== workerId
      )
      state.workers = filterWorkers
      delete state.workerVisits[workerId]
      delete state.workerLeaves[workerId]
      delete state.workerFetchWeeks[workerId]
      delete state.workerRestDays[workerId]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setVisitStatusFilterAction, (state) => {
        // set the cleaner visit and leave to null, to trigger get appointmentData in dashboard component
        const existingWorkerIds = Object.keys(state.workerVisits)
        const { nullWorkerVisits, nullWorkerLeaves, emptyWorkerWeeks } =
          setNullSchedulerData(existingWorkerIds)

        state.workerVisits = nullWorkerVisits
        state.workerLeaves = nullWorkerLeaves
        state.workerFetchWeeks = emptyWorkerWeeks
      })
      .addCase(setQueriedCleanersAction, (state, { payload }) => {
        // init queried cleaner visit and leave to null
        const workerIds = payload.cleaners.map((cleaner) => cleaner.id)
        const { nullWorkerVisits, nullWorkerLeaves, emptyWorkerWeeks } =
          setNullSchedulerData(workerIds)

        state.workerVisits = nullWorkerVisits
        state.workerLeaves = nullWorkerLeaves
        state.workerFetchWeeks = emptyWorkerWeeks
      })
  }
})

export const weeklyWorkersSelector = (state: RootState) =>
  state.weeklySchedulerReducer.workers

export const selectedWorkerVisitsSelector = createSelector(
  [
    selectedCleanerSelector,
    (state: RootState) => state.weeklySchedulerReducer.workerVisits
  ],
  (selectedCleaner, workerVisits) => {
    if (!selectedCleaner) {
      return []
    }

    return workerVisits[selectedCleaner.id]
  }
)

export const selectedWorkerLeavesSelector = createSelector(
  [
    selectedCleanerSelector,
    (state: RootState) => state.weeklySchedulerReducer.workerLeaves
  ],
  (selectedCleaner, workerLeaves) => {
    if (!selectedCleaner) {
      return []
    }

    return workerLeaves[selectedCleaner.id]
  }
)

export const selectedWorkerTasksSelector = createSelector(
  [
    selectedCleanerSelector,
    (state: RootState) => state.weeklySchedulerReducer.workerTasks
  ],
  (selectedCleaner, workerTasks) => {
    if (!selectedCleaner) {
      return []
    }

    return workerTasks[selectedCleaner.id]
  }
)

export const selectedWorkerOffDaysSelector = createSelector(
  [
    selectedCleanerSelector,
    currentDateSelector,
    (state: RootState) => state.weeklySchedulerReducer.workerRestDays
  ],
  (selectedCleaner, currentDate, workerRestDays) => {
    if (!selectedCleaner) {
      return []
    }

    if (!workerRestDays[selectedCleaner.id]) {
      return null
    }

    const [startDate, endDate] = getFirstLastDateinWeek(new Date(currentDate))

    return generateOffdayCell(
      workerRestDays[selectedCleaner.id] ?? [],
      getDateRangeBetween(startDate, endDate)
    )
  }
)

export const selectedWorkerWeeksSelector = createSelector(
  [
    selectedCleanerSelector,
    (state: RootState) => state.weeklySchedulerReducer.workerFetchWeeks
  ],
  (selectedCleaner, workerFetchWeeks) => {
    return selectedCleaner && workerFetchWeeks[selectedCleaner.id]
      ? workerFetchWeeks[selectedCleaner.id]
      : []
  }
)

const { name, actions, reducer } = weeklySchedulerSlice

export { name, actions, reducer, actions as weeklySchedulerActions }
