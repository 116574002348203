import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkerContractById_workerContract as WorkerContractId } from '__generated__/WorkerContractById'
import {
  CleanerContractType,
  Job,
  WorkerContractDays,
  WorkerContractDetails
} from 'ts/types/Cleaner'

import { RootState } from '../../redux/slices'
import {
  PayrollPolicyInput,
  WorkerContractDayTypeEnum,
  WorkerContractWeekTypeEnum
} from './../../__generated__/globalTypes'
import { SelectedSkillSetValue } from './modal/CleanerContractSkillset.screen'
import { nonWorkingOffDay, workerRestDayMapping } from './CleanerContractValues'

export enum ACTIVE_MODAL {
  CONTRACT_TYPE_MODAL = 'CONTRACT_TYPE_MODAL',
  CONTRACT_DETAIL_MODAL = 'CONTRACT_DETAIL_MODAL',
  CONTRACT_REGULAR_WORKDAY_MODAL = 'CONTRACT_REGULAR_WORKDAY_MODAL',
  CONTRACT_STANDARD_WORKING_OFFDAY_MODAL = 'CONTRACT_STANDARD_WORKING_OFFDAY_MODAL',
  CONTRACT_ENHANCED_WORKING_OFFDAY_MODAL = 'CONTRACT_ENHANCED_WORKING_OFFDAY_MODAL',
  CONTRACT_SALARY_ALLOWANCES = 'CONTRACT_SALARY_ALLOWANCES',
  CLEANER_CONTRACT_SKILL_SET = 'CLEANER_CONTRACT_SKILL_SET'
}

export const workerDayTypeModalMapping = {
  [ACTIVE_MODAL.CONTRACT_REGULAR_WORKDAY_MODAL]:
    WorkerContractDayTypeEnum.REGULAR_WORKING_DAY,
  [ACTIVE_MODAL.CONTRACT_STANDARD_WORKING_OFFDAY_MODAL]:
    WorkerContractDayTypeEnum.STANDARD_WORKING_OFF_DAY,
  [ACTIVE_MODAL.CONTRACT_ENHANCED_WORKING_OFFDAY_MODAL]:
    WorkerContractDayTypeEnum.ENHANCED_WORKING_OFF_DAY
}

export type CustomTime = {
  startTime: string | null
  endTime: string | null
  weekType: WorkerContractWeekTypeEnum | null
}

type State = {
  cleanerContractModal: boolean
  contractTerminateModal: boolean
  contractType: CleanerContractType | null
  newContractCreated: boolean
  currentWorkerContract: WorkerContractId | null
  isViewOnly: boolean
  contractTerminated: boolean
  endDate: string | null
  startDate: string | null
  workerContractDetailId: string | null
  isFileDelete: boolean
  unitValue: number
  activeModal: ACTIVE_MODAL | null
  isCancelCreateContractModal: boolean
  workerContractDays: WorkerContractDays[]
  selectedWorkerContractDetail: WorkerContractDetails | null
  workerContractPayrollPolicies: PayrollPolicyInput[]
  workerSkillSets: SelectedSkillSetValue[]
  isEditContractDays: boolean
  isPortfolioDelete: boolean
  job: Job | null
}

export const initialState: State = {
  cleanerContractModal: false,
  isPortfolioDelete: false,
  contractTerminateModal: false,
  contractType: null,
  newContractCreated: false,
  currentWorkerContract: null,
  isViewOnly: false,
  contractTerminated: false,
  endDate: null,
  startDate: null,
  workerContractDetailId: null,
  isFileDelete: false,
  unitValue: 0,
  activeModal: null,
  isCancelCreateContractModal: false,
  workerContractDays: [],
  selectedWorkerContractDetail: null,
  workerContractPayrollPolicies: [],
  workerSkillSets: [],
  isEditContractDays: false,
  job: null
}

const sortByDay = (a: WorkerContractDays, b: WorkerContractDays) =>
  a.day - b.day

const cleanerContractSlice = createSlice({
  name: 'cleanerContractSlice',
  initialState,
  reducers: {
    showCleanerContractModal(state) {
      state.cleanerContractModal = true
      state.activeModal = ACTIVE_MODAL.CONTRACT_TYPE_MODAL
    },
    showCancelCreateContractModal(state) {
      state.isCancelCreateContractModal = true
    },
    hideCancelCreateContractModal(state) {
      state.isCancelCreateContractModal = false
    },
    showUpdateWorkingDaysModal(state) {
      state.cleanerContractModal = true
      state.activeModal = ACTIVE_MODAL.CONTRACT_REGULAR_WORKDAY_MODAL
    },
    editCleanerContractModal(
      state,
      { payload }: PayloadAction<{ contractType: CleanerContractType }>
    ) {
      state.cleanerContractModal = true
      state.activeModal = ACTIVE_MODAL.CONTRACT_DETAIL_MODAL
      state.contractType = payload.contractType
    },
    hideCleanerContractModal(state) {
      state.cleanerContractModal = false
      state.activeModal = null
    },
    clearContractModal(state) {
      state.unitValue = 0
      state.activeModal = null
      state.cleanerContractModal = false
      state.workerContractDays = []
      state.selectedWorkerContractDetail = null
    },
    showContractTerminateContractModal(state) {
      state.contractTerminateModal = true
    },
    hideContractTerminateContractModal(state) {
      state.contractTerminateModal = false
    },
    setContractType(
      state,
      {
        payload: { contractType }
      }: { payload: { contractType: CleanerContractType | null } }
    ) {
      state.contractType = contractType
    },
    setNewContractCreated(
      state,
      {
        payload: { newContractCreated }
      }: { payload: { newContractCreated: boolean } }
    ) {
      state.newContractCreated = newContractCreated
    },
    setCurrentWorkerContract(
      state,
      {
        payload: { workerContract }
      }: {
        payload: { workerContract: WorkerContractId | null }
      }
    ) {
      state.currentWorkerContract = workerContract
      if (workerContract) {
        state.isViewOnly = true
      } else {
        state.isViewOnly = false
      }
    },
    setContractTerminated(
      state,
      {
        payload: { contractTerminated }
      }: { payload: { contractTerminated: boolean } }
    ) {
      state.contractTerminated = contractTerminated
    },
    setEndDate(
      state,
      { payload: { endDate } }: { payload: { endDate: string | null } }
    ) {
      state.endDate = endDate
    },
    setStartDate(
      state,
      { payload: { startDate } }: { payload: { startDate: string | null } }
    ) {
      state.startDate = startDate
    },
    showIsFileDeleteConfirmationModal(state) {
      state.isFileDelete = true
    },
    hideIsFileDeleteConfirmationModal(state) {
      state.isFileDelete = false
    },
    setActiveModal(state, action) {
      state.activeModal = action.payload.modal
    },
    setWorkerContractDays(
      state,
      {
        payload
      }: PayloadAction<{
        workingDays: WorkerContractDays[]
      }>
    ) {
      state.workerContractDays = payload.workingDays.map((workingDay) => ({
        startTime: workingDay.startTime,
        endTime: workingDay.endTime,
        restDay: workingDay.restDay,
        dayType: workingDay.dayType,
        weekType: workingDay.weekType,
        day: workingDay.day
      }))
    },
    updateWorkerContractDay(
      state,
      {
        payload
      }: PayloadAction<{
        values: CustomTime[]
        dayType: WorkerContractDayTypeEnum
      }>
    ) {
      const nonWorkingDaysNumber: number[] = []
      const updatedDays = payload.values
        ? payload.values.reduce((workingDays, time, i) => {
            if (time.startTime && time.endTime && time.weekType) {
              const currentDay: WorkerContractDays = {
                startTime: time.startTime,
                endTime: time.endTime,
                restDay: workerRestDayMapping[payload.dayType],
                dayType: payload.dayType,
                weekType: time.weekType,
                day: i
              }
              workingDays.push(currentDay)
            } else {
              nonWorkingDaysNumber.push(i)
            }

            return workingDays
          }, [] as WorkerContractDays[])
        : []

      const updatedDaysNumber = updatedDays.map(({ day }) => day)

      const otherDays = state.workerContractDays.filter(
        (workingDay) =>
          workingDay.dayType !== payload.dayType &&
          !updatedDaysNumber.includes(workingDay.day)
      )

      const selectedContractDays = [...otherDays, ...updatedDays]
      const selectedContractDaysNumber = selectedContractDays.map(
        ({ day }) => day
      )

      const nonWorkingDays = nonWorkingDaysNumber
        .filter((day) => !selectedContractDaysNumber.includes(day))
        .map(nonWorkingOffDay)

      state.workerContractDays = selectedContractDays
        .concat(nonWorkingDays)
        .sort(sortByDay)
    },
    setUnitValue(state, action: PayloadAction<number>) {
      state.unitValue = action.payload
    },
    setWorkerContractDetail(
      state,
      {
        payload
      }: PayloadAction<{ contractDetail: WorkerContractDetails | null }>
    ) {
      state.selectedWorkerContractDetail = payload.contractDetail
      state.unitValue = payload.contractDetail
        ? payload.contractDetail.unitValue
        : 0
    },
    setSalaryAllowances(
      state,
      { payload }: PayloadAction<{ contractPolicies: PayrollPolicyInput[] }>
    ) {
      state.workerContractPayrollPolicies = payload.contractPolicies
    },
    setWorkerSkillSet(
      state,
      { payload }: PayloadAction<{ skillSet: SelectedSkillSetValue[] }>
    ) {
      state.workerSkillSets = payload.skillSet
    },
    setIsEditContractDays(
      state,
      { payload }: PayloadAction<{ isEditContractDays: boolean }>
    ) {
      state.isEditContractDays = payload.isEditContractDays
    },
    setJob(state, action: PayloadAction<Job>) {
      state.job = action.payload
    },
    clearJob(state) {
      state.job = null
    },
    setIsPortfolioDelete(
      state,
      {
        payload: { isPortfolioDelete }
      }: { payload: { isPortfolioDelete: boolean } }
    ) {
      state.isPortfolioDelete = isPortfolioDelete
    }
  }
})

export const cleanerContractModalSelector = (state: RootState) =>
  state.cleanerContractReducer.cleanerContractModal

export const contractTerminateModalSelector = (state: RootState) =>
  state.cleanerContractReducer.contractTerminateModal

export const contractTypeSelector = (state: RootState) =>
  state.cleanerContractReducer.contractType

export const newContractCreatedSelector = (state: RootState) =>
  state.cleanerContractReducer.newContractCreated

export const currentWorkerContractSelector = (state: RootState) =>
  state.cleanerContractReducer.currentWorkerContract

export const isViewOnlySelector = (state: RootState) =>
  state.cleanerContractReducer.isViewOnly

export const contractTerminatedSelector = (state: RootState) =>
  state.cleanerContractReducer.contractTerminated

export const endDateSelector = (state: RootState) =>
  state.cleanerContractReducer.endDate

export const startDateSelector = (state: RootState) =>
  state.cleanerContractReducer.startDate

export const selectedWorkerContractDetailSelector = (state: RootState) =>
  state.cleanerContractReducer.selectedWorkerContractDetail

export const activeModalSelector = (state: RootState) =>
  state.cleanerContractReducer.activeModal

export const isFileDeleteConfirmationModalSelector = (state: RootState) =>
  state.cleanerContractReducer.isFileDelete

export const workerContractDaysSelector = (state: RootState) =>
  state.cleanerContractReducer.workerContractDays

export const prevSelectedDaysSelector = createSelector(
  workerContractDaysSelector,
  activeModalSelector,
  (contractDays, activeModal) => {
    if (activeModal === ACTIVE_MODAL.CONTRACT_ENHANCED_WORKING_OFFDAY_MODAL) {
      const prevSelectedDays = contractDays.filter((workingDay) =>
        [
          WorkerContractDayTypeEnum.REGULAR_WORKING_DAY,
          WorkerContractDayTypeEnum.STANDARD_WORKING_OFF_DAY
        ].includes(workingDay.dayType)
      )
      return prevSelectedDays.map(({ day }) => day)
    }

    if (activeModal === ACTIVE_MODAL.CONTRACT_STANDARD_WORKING_OFFDAY_MODAL) {
      const prevSelectedDays = contractDays.filter((workingDay) =>
        [WorkerContractDayTypeEnum.REGULAR_WORKING_DAY].includes(
          workingDay.dayType
        )
      )
      return prevSelectedDays.map(({ day }) => day)
    }

    return []
  }
)

export const unitValueSelector = (state: RootState) =>
  state.cleanerContractReducer.unitValue

export const workerContractPayrollPoliciesSelector = (state: RootState) =>
  state.cleanerContractReducer.workerContractPayrollPolicies

export const workerSkillSetsSelector = (state: RootState) =>
  state.cleanerContractReducer.workerSkillSets

export const isCancelCreateContractModalSelector = (state: RootState) =>
  state.cleanerContractReducer.isCancelCreateContractModal

export const isEditContractDaysSelector = (state: RootState) =>
  state.cleanerContractReducer.isEditContractDays

export const contractJobSelector = (state: RootState) =>
  state.cleanerContractReducer.job

export const isPortfolioDeleteSelector = (state: RootState): boolean =>
  state.cleanerContractReducer.isPortfolioDelete

export const { name, actions, reducer } = cleanerContractSlice
