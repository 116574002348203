import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/slices'

type State = {
  projectedPayrollId: string
  isDetailsModalOpen: boolean
}

export const initialState: State = {
  projectedPayrollId: '',
  isDetailsModalOpen: false
}

const projectedPayrollsSlice = createSlice({
  name: 'projectedPayrolls',
  initialState,
  reducers: {
    setProjectedPayrollId(state, action: PayloadAction<{ id: string }>) {
      state.projectedPayrollId = action.payload.id
    },
    setIsDetailsModalOpen(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.isDetailsModalOpen = action.payload.isOpen
    }
  }
})

export const porjectedPayrollIdSelector = (state: RootState) =>
  state.projectedPayrollsReducer.projectedPayrollId

export const isDetailsModalOpenSelector = (state: RootState) =>
  state.projectedPayrollsReducer.isDetailsModalOpen

export const { name, actions, reducer } = projectedPayrollsSlice
