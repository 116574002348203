export enum ShortDay {
  MO = 'Mo',
  TU = 'Tu',
  WE = 'We',
  TH = 'Th',
  FR = 'Fr',
  SA = 'Sa',
  SU = 'Su'
}

export const shortDayIndex = (): ShortDay[] => {
  return ShortDaysList.map((day) => day)
}

export const shortDayByIndex = (index: number): ShortDay => {
  return ShortDaysList[index]
}

export const indexByShortDay = (day: ShortDay): number => {
  return ShortDaysList.indexOf(day)
}

export enum Day {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export const ShortDaysList: ShortDay[] = [
  ShortDay.MO,
  ShortDay.TU,
  ShortDay.WE,
  ShortDay.TH,
  ShortDay.FR,
  ShortDay.SA,
  ShortDay.SU
]

export const DaysList: Day[] = [
  Day.MONDAY,
  Day.TUESDAY,
  Day.WEDNESDAY,
  Day.THURSDAY,
  Day.FRIDAY,
  Day.SATURDAY,
  Day.SUNDAY
]

export const daysNumber = ShortDaysList.map((_, number) => number)

export function sortShortDays(shortDays: ShortDay[]): ShortDay[] {
  return shortDays.sort(shortDaysSorter)
}

export function shortDaysSorter(a: ShortDay, b: ShortDay): number {
  return ShortDaysList.indexOf(a) - ShortDaysList.indexOf(b)
}

export function mapShortToDay(shortDay: ShortDay): Day {
  switch (shortDay) {
    case ShortDay.MO:
      return Day.MONDAY
    case ShortDay.TU:
      return Day.TUESDAY
    case ShortDay.WE:
      return Day.WEDNESDAY
    case ShortDay.TH:
      return Day.THURSDAY
    case ShortDay.FR:
      return Day.FRIDAY
    case ShortDay.SA:
      return Day.SATURDAY
    case ShortDay.SU:
      return Day.SUNDAY
  }
}

export function mapDayToShort(day: Day): ShortDay {
  switch (day) {
    case Day.MONDAY:
      return ShortDay.MO
    case Day.TUESDAY:
      return ShortDay.TU
    case Day.WEDNESDAY:
      return ShortDay.WE
    case Day.THURSDAY:
      return ShortDay.TH
    case Day.FRIDAY:
      return ShortDay.FR
    case Day.SATURDAY:
      return ShortDay.SA
    case Day.SUNDAY:
      return ShortDay.SU
  }
}
