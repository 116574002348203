import CircularProgress from '@luce/ui-kit/components/atom/CircularProgress'
import Box from '@luce/ui-kit/components/base/Box'
import React from 'react'

const Loader = () => {
  return (
    <Box display="flex" justifyContent="center" width="100%" mt={2}>
      <CircularProgress />
    </Box>
  )
}

export default Loader
