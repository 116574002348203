import * as Sentry from './services/sentry'

import ReactDOM from 'react-dom/client'
import App from './LuceApp'
import './index.css'

Sentry.init()


ReactDOM.createRoot(document.getElementById('root')!).render(
  <App />
)
