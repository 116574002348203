import { combineReducers } from '@reduxjs/toolkit'
import { reducer as projectedPayrollsReducer } from 'components/projectedPayrolls/projectedPayrolls.slice'

import { reducer as accessControlReducer } from '../../components/auth/accessControl/accessControll.slice'
import { reducer as bookingReducer } from '../../components/booking/booking.slice'
import { reducer as changeHistoryReducer } from '../../components/changeHistory/changeHistory.slice'
import { reducer as workersReducer } from '../../components/cleaner/workers.slice'
import { reducer as cleanerContractReducer } from '../../components/cleanerContract/cleanerContract.slice'
import { reducer as clientsReducer } from '../../components/client/clients.slice'
import { reducer as dailySchedulerReducer } from '../../components/dashboard/dailyScheduler.slice'
import { reducer as schedulerCleanersReducer } from '../../components/dashboard/schedulerCleaners.slice'
import { reducer as weeklySchedulerReducer } from '../../components/dashboard/weeklyScheduler.slice'
import { reducer as creditReducer } from '../../components/clientCredit/credit.slice'
import { reducer as invoiceReducer } from '../../components/invoice/invoice.slice'
import { reducer as jobReducer } from '../../components/job/job.slice'
import { reducer as jobDetailsReducer } from '../../components/job/jobDetails.slice'
import { reducer as jobsListReducer } from '../../components/jobsList/jobsList.slice'
import { reducer as leaveReducer } from '../../components/leave/leave.slice'
import { reducer as lineItemReducer } from '../../components/lineItem/lineItem.slice'
import { reducer as packageReducer } from '../../components/package/package.slice'
import { reducer as packageRequestReducer } from '../../components/packageRequest/packageRequest.slice'
import { reducer as payrollReducer } from '../../components/payroll/payroll.slice'
import { reducer as promoCodeReducer } from '../../components/promoCode/promoCode.slice'
import { reducer as searchCleanerReducer } from '../../components/search/Cleaner/searchCleaner.slice'
import { reducer as searchClientReducer } from '../../components/search/client/searchClient.slice'
import { reducer as visitReducer } from '../../components/visit/visit.slice'
import { reducer as voucherReducer } from '../../components/voucher/voucher.slice'
import { reducer as workerTaskReducer } from '../../components/workerTask/workerTask.slice'
import { reducer as rewardReducer } from '../../components/reward/reward.slice'
import { reducer as appReducer } from './appSlice'
import { reducer as modalReducer } from './modalSlice'
import { reducer as notificationReducer } from '../../components/notification/notifications.slice'

const rootReducer = combineReducers({
  appReducer,
  clientsReducer,
  workersReducer,
  cleanerContractReducer,
  invoiceReducer,
  creditReducer,
  leaveReducer,
  payrollReducer,
  schedulerCleanersReducer,
  dailySchedulerReducer,
  modalReducer,
  lineItemReducer,
  packageReducer,
  jobReducer,
  jobDetailsReducer,
  jobsListReducer,
  searchCleanerReducer,
  searchClientReducer,
  visitReducer,
  changeHistoryReducer,
  packageRequestReducer,
  weeklySchedulerReducer,
  projectedPayrollsReducer,
  workerTaskReducer,
  accessControlReducer,
  promoCodeReducer,
  voucherReducer,
  rewardReducer,
  notificationReducer,
  bookingReducer // for test app
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
