import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../redux/slices'
import { ShortDay } from '../../ts/types/Days'
import { Task } from '../../ts/types/Tasks'
import { ServiceTime } from '../package/package.slice'

interface NewJob {
  scheduledTasks: Task[]
  startDate: string
  endDate: string | null
  scheduledDays: ShortDay[]
  scheduledTimes: ServiceTime[]
}

type NewJobAction = {
  payload: NewJob
}

type ServiceDaysAction = {
  payload: {
    serviceDays: ShortDay[]
  }
}

type ChangeTimeAction = {
  payload: {
    day: ShortDay
    time: keyof ServiceTime
    value: string
  }
}

type State = {
  cleanerId: string | undefined
  contract: string | undefined
  startDate: string | undefined
  endDate: string | null
  scheduledDays: ShortDay[]
  scheduledTimes: ServiceTime[]
  serviceDays: ShortDay[]
  serviceTimes: ServiceTime[]
  scheduledTasks: Task[]
  assignedTasks: string[]
  addNewJobOpen?: boolean
  isEdit: boolean
}

export const initialState: State = {
  startDate: undefined,
  endDate: null,
  cleanerId: '',
  contract: '',
  scheduledDays: [],
  scheduledTimes: [],
  serviceDays: [],
  serviceTimes: [],
  scheduledTasks: [],
  assignedTasks: [],
  addNewJobOpen: false,
  isEdit: false
}

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    prepareNewJob(state, action: NewJobAction): void {
      const {
        startDate,
        endDate,
        scheduledDays,
        scheduledTimes,
        scheduledTasks
      } = action.payload
      state.scheduledTasks = scheduledTasks
      state.startDate = startDate
      state.endDate = endDate
      state.scheduledDays = scheduledDays
      state.scheduledTimes = scheduledTimes
      state.serviceDays = []
      state.serviceTimes = []
    },
    setServiceDays(state, action: ServiceDaysAction): void {
      const { serviceDays } = action.payload
      const times: ServiceTime[] = serviceDays.map((day) => {
        if (day) {
          return {
            startTime: '08:00',
            endTime: null
          }
        }
        return day
      })

      const daysIndices = serviceDays.map((day) => {
        return state.scheduledDays.indexOf(day)
      })

      const tasksIds = state.scheduledTasks
        .filter(({ day }) => daysIndices.includes(day))
        .map(({ id }) => id)

      state.assignedTasks = tasksIds
      state.serviceDays = serviceDays
      state.serviceTimes = times
    },
    // setScheduledDays(state, action: ServiceDaysAction): void {
    //   const { serviceDays } = action.payload
    //   const times: ServiceTime[] = serviceDays.map((day) => {
    //     if (day) {
    //       return {
    //         startTime: '08:00',
    //         endTime: null
    //       }
    //     }
    //     return day
    //   })
    //   state.scheduledDays = serviceDays
    //   state.scheduledTimes = times
    // },
    changeCleanerId(state, action): void {
      state.cleanerId = action.payload.cleanerId
    },
    changeTime(state, action: ChangeTimeAction): void {
      const { day, time, value } = action.payload
      const dayIndex = state.serviceDays.indexOf(day)
      state.serviceTimes[dayIndex][time] = value
    },
    setAddNewJobOpen(state, action): void {
      const { addNewJobOpen } = action.payload
      state.addNewJobOpen = addNewJobOpen
    },
    resetJob(): State {
      return initialState
    },
    setEdit(state, action: PayloadAction<boolean>): void {
      state.isEdit = action.payload
    }
  }
})

export const startDateSelector = (state: RootState) =>
  state.jobReducer.startDate
export const endDateSelector = (state: RootState) => state.jobReducer.endDate

export const jobDateSelector = (state: RootState) => ({
  startDate: state.jobReducer.startDate!,
  endDate: state.jobReducer.endDate!
})

export const serviceDaysSelector = (state: RootState) =>
  state.jobReducer.serviceDays

export const scheduledDaysSelector = (state: RootState) =>
  state.jobReducer.scheduledDays

export const serviceTimesSelector = (state: RootState) =>
  state.jobReducer.serviceTimes

export const scheduledTimesSelector = (state: RootState) =>
  state.jobReducer.scheduledTimes

export const assignedTasksSelector = (state: RootState) =>
  state.jobReducer.assignedTasks

export const cleanerIdSelector = (state: RootState) =>
  state.jobReducer.cleanerId

export const addNewJobOpenSelector = (state: RootState) =>
  state.jobReducer.addNewJobOpen

export const isEditSelector = (state: RootState): boolean =>
  state.jobReducer.isEdit

export const { name, actions, reducer, actions: jobActions } = jobSlice
