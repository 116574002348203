import { FetchResult, useMutation, MutationResult } from '@apollo/react-hooks'
import { createWorkerTipInput } from '__generated__/globalTypes'


import {
  CreateWorkerTipMessage,
  CreateWorkerTipMessageVariables
} from '../../../../src/__generated__/CreateWorkerTipMessage'

import CREATE_WORKER_TIP from   '../__graphql__/CreateWorkerTipMessage.graphql'

type CreateWorkerTipMessageResult = FetchResult<CreateWorkerTipMessage>

export default function useCreateWorkerTip(): [
  (input: createWorkerTipInput) => Promise<CreateWorkerTipMessageResult>,
  MutationResult<CreateWorkerTipMessage>
] {
  const [mutation, result] = useMutation<
    CreateWorkerTipMessage,
    CreateWorkerTipMessageVariables
  >(CREATE_WORKER_TIP)

  function createWorkerTip(
    input: createWorkerTipInput
  ): Promise<CreateWorkerTipMessageResult> {
    return mutation({
      variables: {
        input
      }
    })
  }

  return [createWorkerTip, result]
}

export { useCreateWorkerTip }
