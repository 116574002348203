import Avatar, { AvatarProps } from '@luce/ui-kit/components/avatar'
import React from 'react'

interface Props {
  rotation?: number
  onClick?(): void
  avatarLetter?: string
  loading?: boolean
  img?: string
  alt?: string
}

export type AvatarUserProps = AvatarProps & Props

const AvatarPreview: React.FC<AvatarUserProps> = (props) => {
  const {
    rotation,
    onClick,
    avatarLetter,
    loading,
    img,
    alt,
    customSize,
    imageAvatar,
    ...rest
  } = props
  const imgProps = rotation
    ? { style: { transform: `rotate(${rotation}deg)` } }
    : {}
  return (
    <Avatar
      customSize={customSize}
      src={img}
      alt={alt}
      onClick={onClick}
      imgProps={imgProps}
      imageAvatar={imageAvatar}
      {...rest}
    >
      {!props.img && !loading ? avatarLetter : null}
    </Avatar>
  )
}

export default AvatarPreview
