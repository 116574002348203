import Box from '@luce/ui-kit/components/base/Box'
import { makeStyles } from '@luce/ui-kit/themes/ThemeProvider'

import Loader from './Loader'

const useStyles = makeStyles(() => ({
  loaderPostion: {
    position: 'absolute',
    margin: 'auto',
    height: '100%',
    top: '50%',
    left: '50%'
  }
}))

const LoadingOverlay = () => {
  const classes = useStyles()

  return (
    <Box className={classes.loaderPostion}>
      <Loader />
    </Box>
  )
}

export default LoadingOverlay
