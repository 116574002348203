import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Worker, Worker360 } from 'ts/types/Cleaner'

import { RootState } from '../../redux/slices'

type State = {
  newWorkerId: string | null
  loading: boolean
  error: boolean
  allWorkers: Worker[] | null
  currentWorker: Worker360 | null
  workerModal: boolean
  isWorkerUpdated: boolean
  showRatingHistory: boolean
}

export const initialState: State = {
  newWorkerId: null,
  loading: false,
  error: false,
  allWorkers: <Worker[] | null>null,
  currentWorker: <Worker360 | null>null,
  workerModal: false,
  isWorkerUpdated: false,
  showRatingHistory: false
}

const workerSlice = createSlice({
  name: 'worker',
  initialState,
  reducers: {
    fetchSuccess() {},
    fetchFailure() {},
    errors(state) {
      state.error = true
    },
    setWorker(
      state,
      { payload: { worker } }: { payload: { worker: Worker360 } }
    ) {
      state.currentWorker = worker
    },
    showWorkerModal(state) {
      state.workerModal = true
    },
    hideWorkerModal(state) {
      state.workerModal = false
    },
    setNewWorkerId(
      state,
      { payload: { workerId } }: { payload: { workerId: string } }
    ) {
      state.newWorkerId = workerId
    },
    clearCurrentWorker(state) {
      state.currentWorker = null
    },
    setIsShowRatingHistory(
      state,
      action: PayloadAction<{ isShowRatingHistory: boolean }>
    ) {
      state.showRatingHistory = action.payload.isShowRatingHistory
    },
    setIsWorkerUpdated(
      state,
      action: PayloadAction<{ isWorkerUpdated: boolean }>
    ) {
      state.isWorkerUpdated = action.payload.isWorkerUpdated
    }
  }
})

export const loadingSelector = (state: RootState) =>
  state.workersReducer.loading

export const errorsSelector = (state: RootState) => state.workersReducer.error

export const allClientsSelector = (state: RootState) =>
  state.workersReducer.allWorkers

export const currentWorkerSelector = (state: RootState) =>
  state.workersReducer.currentWorker

export const workerModalSelector = (state: RootState) =>
  state.workersReducer.workerModal

export const newWorkerIdSelector = (state: RootState) =>
  state.workersReducer.newWorkerId

export const showRatingHistorySelector = (state: RootState) =>
  state.workersReducer.showRatingHistory

export const isWorkerUpdatedSelector = (state: RootState) =>
  state.workersReducer.isWorkerUpdated

export const { name, actions, reducer } = workerSlice
