import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/slices'
import { WorkerTaskActions } from 'ts/types/Cleaner'

type State = {
  showModal: boolean
  workerId: string
  taskId?: string
  refetchRequest: boolean
  showActionModal: boolean
  selectedAction: WorkerTaskActions
}

const initialState: State = {
  showModal: false,
  workerId: '',
  refetchRequest: false,
  showActionModal: false,
  selectedAction: ''
}

const workerTaskSlice = createSlice({
  name: 'workerTaskSlice',
  initialState,
  reducers: {
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showModal = action.payload
    },
    setWorkerId(state, action: PayloadAction<string>) {
      state.workerId = action.payload
    },
    setTaskId(state, action: PayloadAction<string>) {
      state.taskId = action.payload
    },
    setRefetchRequest(state, action: PayloadAction<boolean>) {
      state.refetchRequest = action.payload
    },
    setShowActionModal(state, action: PayloadAction<boolean>) {
      state.showActionModal = action.payload
    },
    setSelectedAction(state, action: PayloadAction<WorkerTaskActions>) {
      state.selectedAction = action.payload
    }
  }
})

export const showModalSelector = (state: RootState) =>
  state.workerTaskReducer.showModal
export const workerIdSelector = (state: RootState) =>
  state.workerTaskReducer.workerId
export const taskIdSelector = (state: RootState) =>
  state.workerTaskReducer.taskId
export const refetchRequestSelector = (state: RootState) =>
  state.workerTaskReducer.refetchRequest
export const showActionModalSelector = (state: RootState) =>
  state.workerTaskReducer.showActionModal
export const selectedActionSelector = (state: RootState) =>
  state.workerTaskReducer.selectedAction

const { name, actions, reducer } = workerTaskSlice
export { name, actions, reducer }
