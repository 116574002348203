import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../redux/slices'

type State = {
  loading: boolean
  error: boolean
  detailModal: boolean
  currentChangeHistoryObj: ChangeHistory | null
}

export const initialState: State = {
  loading: false,
  error: false,
  detailModal: false,
  currentChangeHistoryObj: null
}

export interface ChangeHistory {
  id: string
  auditableType: string
  auditableId: string
  action: string
  datetime: string
  name: string
  changes: string
}

export const accessToModal: string[] = [
  'CLIENT',
  'CONTACT',
  'ADDRESS',
  'CONTRACT',
  'PACKAGE',
  'JOB',
  'VISIT',
  'INVOICE'
]

const changeHistorySlice = createSlice({
  name: 'changeHistorySlice',
  initialState,
  reducers: {
    errors(state) {
      state.error = true
    },
    showDetailModal(state) {
      state.detailModal = true
    },
    hideDetailModal(state) {
      state.detailModal = false
    },
    setCurrentChangeHistoryObj(
      state,
      {
        payload: { changeHistoryObj }
      }: { payload: { changeHistoryObj: ChangeHistory } }
    ) {
      state.currentChangeHistoryObj = changeHistoryObj
    },
    clearCurrentChangeHistoryObj(state) {
      state.currentChangeHistoryObj = null
    }
  }
})

export const loadingSelector = (state: RootState) =>
  state.changeHistoryReducer.loading

export const errorsSelector = (state: RootState) =>
  state.changeHistoryReducer.error

export const currentChangeHistoryObjSelector = (
  state: RootState
): ChangeHistory | null => state.changeHistoryReducer.currentChangeHistoryObj

export const detailModalSelector = (state: RootState) =>
  state.changeHistoryReducer.detailModal

const { name, actions, reducer } = changeHistorySlice
export { name, actions, reducer, actions as changeHistoryActions }
