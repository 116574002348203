import Box from '@luce/ui-kit/components/base/Box'
import Typography from '@luce/ui-kit/components/base/Typography'
import Button from '@luce/ui-kit/components/button/Button'
import LuceAppBar from '@luce/ui-kit/components/molecules/AppBar'
import { makeStyles, Theme } from '@luce/ui-kit/themes/ThemeProvider'
import LuceTheme from '@luce/ui-kit/themes/default'
import AvatarPreview from 'components/user/Avatar'
import useAuth from 'hooks/AuthHook'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { getAvatarLetter } from 'utils/helpers'

import { actions } from '../../redux/slices/modalSlice'
import NavigationDrawer, { ActivePageRoute } from './NavigationDrawer'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1201,
    position: 'relative'
  },
  title: {
    marginRight: theme.spacing(2)
  },
  placeholder: {
    flexGrow: 1
  },
  linkStyle: {
    textDecoration: 'none',
    color: theme.palette.common.white
  },
  avatar: {
    ...LuceTheme.typography.overline,
    color: LuceTheme.palette.primary.main,
    background: LuceTheme.palette.grey[400],
    marginRight: theme.spacing(1.5),
    textDecoration: 'none',
    '&:hover': {
      background: LuceTheme.palette.grey[600]
    }
  }
}))

type ActivePageName =
  | 'Luce'
  | 'Weekly Job Availability'
  | 'Daily Job Availability'
  | 'Clients'
  | 'Workers'
  | 'Visits'
  | 'Payroll'
  | 'Invoices'
  | 'Bank Statements Cleaner'
  | 'Daily Visit Assignment'
  | 'Job Assignment'
  | 'Workers Resignation'
  | 'Worker Attendances'
  | 'Worker Leaves'
  | 'Worker Tip Message'
  | 'Reward'
  | 'Promo Codes'
  | 'Promo Management'
  | 'Access Control'
  | 'Projected Payrolls'
  | 'New Recurring Clients'
  | 'Reschedule Client Visit (Beta)'
  | 'Package Requests'
  | 'Booking Schedule: Home (Beta)'
  | 'Booking Schedule: Aircon (Beta)'
  | 'Notifications'
  | 'Sales Agent Commission'

const locationPathNames: { [key in ActivePageRoute]: ActivePageName } = {
  weekly: 'Weekly Job Availability',
  daily: 'Daily Job Availability',
  clients: 'Clients',
  workers: 'Workers',
  visits: 'Visits',
  payroll: 'Payroll',
  invoices: 'Invoices',
  'bank-statements-cleaner': 'Bank Statements Cleaner',
  'daily-visit-assignment': 'Daily Visit Assignment',
  'job-assignment': 'Job Assignment',
  'workers-resignation': 'Workers Resignation',
  'worker-attendances': 'Worker Attendances',
  'worker-leaves': 'Worker Leaves',
  'worker-tip-message': 'Worker Tip Message',
  rewards: 'Reward',
  'promo-codes': 'Promo Codes',
  'promo-management': 'Promo Management',
  'auth/access-control': 'Access Control',
  'reports/projected-payrolls': 'Projected Payrolls',
  'reports/new-recurring-clients': 'New Recurring Clients',
  'test/reschedule-client-visit': 'Reschedule Client Visit (Beta)',
  'package-requests': 'Package Requests',
  'test/booking/home': 'Booking Schedule: Home (Beta)',
  'test/booking/aircon': 'Booking Schedule: Aircon (Beta)',
  notifications: 'Notifications',
  'sales-agent-commission': 'Sales Agent Commission'
}

interface Props {}

const AppBar: React.FC<Props> = (_props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useAuth()
  const user = auth?.user
  const location = useLocation()
  const [activePage, setIsActivePage] = useState<ActivePageName>('Luce')
  const [activePageRoute, setIsActivePageRoute] =
    useState<ActivePageRoute>('daily')

  const isValidLocation = (
    value: string
  ): value is keyof typeof locationPathNames => {
    return value in locationPathNames
  }

  useEffect(() => {
    const pathName = location.pathname.slice(1)
    if (isValidLocation(pathName)) {
      setIsActivePage(locationPathNames[pathName])
      setIsActivePageRoute(pathName)
    }
  }, [location.pathname])

  const onLogout = () => {
    auth?.logout()
    history.replace('/')
  }

  const showProfileModal = () => {
    dispatch(actions.showModal())
  }

  const classes = useStyles()
  return (
    <LuceAppBar className={classes.root} position="static">
      {user ? (
        <Box display="flex" alignItems="center">
          <NavigationDrawer
            onLogout={onLogout}
            activePageRoute={activePageRoute}
          />
          <Typography>{activePage}</Typography>
        </Box>
      ) : (
        <RouterLink className={classes.linkStyle} to="/login">
          <Button variants="primary">Login</Button>
        </RouterLink>
      )}
      <div className={classes.placeholder} />
      {user && (
        <>
          <Box display="none">
            <Button variant="contained" onClick={showProfileModal}>
              Show Profile
            </Button>
          </Box>
          <Box
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {}}
            color="inherit"
          >
            <RouterLink className={classes.linkStyle} to="/profile">
              <AvatarPreview
                img={user.avatarUrl}
                customSize="medium"
                avatarLetter={getAvatarLetter(user)}
                className={classes.avatar}
              />
            </RouterLink>
          </Box>
          <RouterLink className={classes.linkStyle} to="/profile">
            <Typography variant="subtitle1">{user.firstName}</Typography>
          </RouterLink>
        </>
      )}
    </LuceAppBar>
  )
}

export default AppBar
