import { Suspense, lazy } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from 'components/appbar/Appbar'
import LoadingOverlay from 'components/loader/LoadingOverlay'
import WorkerTipContainer from 'components/workerTip/WorkerTip.container'
import PrivateRoute from 'components/route/PrivateRoute'
import ProfileModal from 'components/user/ProfileModal'
import { AuthProvider } from 'context/AuthContext'
import {
  PickersUtilsProvider,
  DateFnsUtils,
  defaultTheme,
  themeProvider as ThemeProvider
} from 'luce-ui-components'
import { MaintenanceModeProvider } from 'context/MaintenanceContext'
import { apolloClient } from 'services/apollo'
import { SnackbarProvider } from 'notistack'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux/configureStore'

const InviteContainer = lazy(() => import('./components/auth/Invite.container'))
const SignInContainer = lazy(() => import('./components/auth/SignIn.container'))
const ForgotPasswordContainer = lazy(
  () => import('./components/auth/forgotPassword/ForgotPassword.container')
)
const ResetPasswordContainer = lazy(
  () => import('./components/auth/resetPassword/ResetPassword.container')
)
const CleanerResignationContainer = lazy(
  () => import('./components/cleanerResignation/CleanerResignation.container')
)
const ClientsContainer = lazy(
  () => import('./components/client/Clients.container')
)
const InvoiceContainer = lazy(
  () => import('./components/invoice/Invoice.container')
)
const BankStatementsCleanerContainer = lazy(
  () => import('./components/bankStatement/BankStatementsCleaner.container')
)
const LeaveContainer = lazy(() => import('./components/leave/Leave.container'))
const PayrollContainer = lazy(
  () => import('./components/payroll/Payroll.container')
)
const DailyScheduleContainer = lazy(
  () => import('./components/schedule/DailySchedule.container')
)
const WeeklyScheduleContainer = lazy(
  () => import('./components/schedule/WeeklySchedule.container')
)
const CleanerPage = lazy(
  () => import('./components/cleaner/360/CleanerPage.container')
)
const CleanersContainer = lazy(
  () => import('./components/cleaner/Cleaners.container')
)
const CleanerContract = lazy(
  () => import('./components/cleanerContract/CleanerContract.container')
)
const ClientPage = lazy(
  () => import('./components/client/ClientPage.container')
)
const Contract = lazy(() => import('./components/contract/Contract.container'))
const JobAssignment = lazy(
  () => import('./components/dailyJobAssignment/JobAssignment')
)
const DailyVisitAssignment = lazy(
  () =>
    import('./components/dailyVisitAssignment/DailyVisitAssignment.page')
)
const JobPage = lazy(() => import('./components/job/JobPage.container'))
const Visit = lazy(() => import('./components/visit/Visit.container'))
const VisitPage = lazy(() => import('./components/visit/VisitPage'))
const ProjectedPayrolls = lazy(
  () => import('./components/projectedPayrolls/ProjectedPage.container')
)
const NewRecurringClients = lazy(
  () =>
    import(
      './components/reports/newRecurringClient/newRecurringClientsList.container'
    )
)
const RescheduleVisitPage = lazy(
  () => import('./components/clientReschedule/RescheduleVisitPage')
)
const PackageRequestContainer = lazy(
  () => import('./components/packageRequest/PackageRequest.container')
)
const BookingPageContainer = lazy(
  () => import('./components/booking/BookingPage.container')
)
const NotFoundPageContainer = lazy(
  () => import('./components/dashboard/NotFoundPage.container')
)
const AccessControlContainer = lazy(
  () => import('./components/auth/accessControl/AccesControl.container')
)
const PromoCodeContainer = lazy(
  () => import('./components/promoCode/PromoCode.container')
)
const WorkerAttendanceListContainer = lazy(
  () =>
    import(
      './components/workerAttendance/workerAttendanceList/WorkerAttendanceList.container'
    )
)
const MasterRewardPageContainer = lazy(
  () => import('./components/reward/MasterRewardPage.container')
)
const RewardPageContainer = lazy(
  () => import('./components/reward/RewardPage.container')
)

const NotificationContainer = lazy(
  () => import('components/notification/Notification.container')
)

const NotificationTemplatePage = lazy(
  () => import('components/notification/NotificationTemplatePage.container')
)

const NotificationTemplateHistoryPage = lazy(
  () =>
    import('components/notification/NotificationTemplateHistoryPage.container')
)

const PromoManagement = lazy(() => import('components/promoManagement/promotionList/PromotionList.container'))

const SalesAgentCommission = lazy(
  () =>
    import(
      'components/salesAgentCommission/salesAgentCommissionList/SalesAgentCommissionList.container'
    )
)

export default function LuceApp() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <ReduxProvider store={store}>
        <PickersUtilsProvider utils={DateFnsUtils}>
          <ApolloProvider client={apolloClient}>
            <MaintenanceModeProvider>
              <SnackbarProvider maxSnack={3}>
                <AuthProvider>
                  <Router>
                    <div>
                      <AppBar />
                      <Suspense fallback={<LoadingOverlay />}>
                        <Switch>
                          <Route exact path="/">
                            <Redirect to="/login" />
                          </Route>
                          <Route path="/login" component={SignInContainer} />
                          <Route
                            path="/reset-password"
                            component={ResetPasswordContainer}
                          />
                          <Route
                            path="/forgot-password"
                            component={ForgotPasswordContainer}
                          />
                          <PrivateRoute
                            path="/weekly"
                            component={WeeklyScheduleContainer}
                          />
                          <PrivateRoute
                            path="/daily"
                            component={DailyScheduleContainer}
                          />
                          <Route
                            path="/invite/:code"
                            component={InviteContainer}
                          />
                          <PrivateRoute
                            path="/jobs/:jobId"
                            component={JobPage}
                          />
                          <PrivateRoute
                            path="/clients/:id/contract/new"
                            component={Contract}
                          />
                          <PrivateRoute
                            path="/clients/:id/contract/:contractId"
                            component={Contract}
                          />
                          <PrivateRoute
                            path="/clients/:id/page"
                            component={ClientPage}
                          />
                          <PrivateRoute
                            path="/workers/:id/contract/new"
                            component={CleanerContract}
                          />
                          <PrivateRoute
                            path="/workers/:id/contract/:workerContractId"
                            component={CleanerContract}
                          />
                          <PrivateRoute
                            path="/workers/:id/page"
                            component={CleanerPage}
                          />
                          <PrivateRoute
                            path="/clients"
                            component={ClientsContainer}
                          />
                          <PrivateRoute
                            path="/workers"
                            component={CleanersContainer}
                          />
                          <PrivateRoute
                            path="/payroll"
                            component={PayrollContainer}
                          />
                          <PrivateRoute
                            path="/reports/projected-payrolls"
                            component={ProjectedPayrolls}
                          />
                          <PrivateRoute
                            path="/worker-leaves"
                            component={LeaveContainer}
                          />
                          <PrivateRoute
                            path="/worker-tip-message"
                            component={WorkerTipContainer}
                          />
                          <PrivateRoute
                            path="/invoices"
                            component={InvoiceContainer}
                          />
                          <PrivateRoute
                            path="/bank-statements-cleaner"
                            component={BankStatementsCleanerContainer}
                          />
                          <PrivateRoute
                            path="/workers-resignation"
                            component={CleanerResignationContainer}
                          />
                          <PrivateRoute
                            path="/daily-visit-assignment"
                            component={DailyVisitAssignment}
                          />
                          <PrivateRoute
                            path="/job-assignment"
                            component={JobAssignment}
                          />
                          <PrivateRoute
                            exact
                            path="/visits"
                            component={Visit}
                          />
                          <PrivateRoute
                            path="/visits/:visitPageId"
                            component={VisitPage}
                          />
                          <PrivateRoute
                            path="/reports/new-recurring-clients"
                            component={NewRecurringClients}
                          />
                          <PrivateRoute
                            path="/test/reschedule-client-visit"
                            component={RescheduleVisitPage}
                          />
                          <PrivateRoute
                            path="/package-requests"
                            component={PackageRequestContainer}
                          />
                          <PrivateRoute
                            path="/test/booking/:type(home|aircon)"
                            component={BookingPageContainer}
                          />
                          <PrivateRoute
                            path="/auth/access-control"
                            component={AccessControlContainer}
                          />
                          <PrivateRoute
                            path="/promo-codes"
                            component={PromoCodeContainer}
                          />
                          <PrivateRoute
                          path="/promo-management"
                          component={PromoManagement}
                          />
                          <PrivateRoute
                            path="/worker-attendances"
                            component={WorkerAttendanceListContainer}
                          />
                          <PrivateRoute
                            path="/sales-agent-commission"
                            component={SalesAgentCommission}
                          />
                          <PrivateRoute
                            path="/rewards"
                            component={MasterRewardPageContainer}
                          />
                          <PrivateRoute
                            path="/reward/:id/page"
                            component={RewardPageContainer}
                          />
                          <PrivateRoute
                            path="/notifications/:id/template/:templateId/histories"
                            component={NotificationTemplateHistoryPage}
                          />
                          <PrivateRoute
                            path="/notifications/:id/page"
                            component={NotificationTemplatePage}
                          />
                          <PrivateRoute
                            path="/notifications"
                            component={NotificationContainer}
                          />
                          <Route component={SignInContainer} />
                          <Route component={NotFoundPageContainer} />
                        </Switch>
                      </Suspense>
                      <ProfileModal />
                    </div>
                  </Router>
                </AuthProvider>
              </SnackbarProvider>
            </MaintenanceModeProvider>
          </ApolloProvider>
        </PickersUtilsProvider>
      </ReduxProvider>
    </ThemeProvider>
  )
}
