import { createSlice } from '@reduxjs/toolkit'
import { CleanerData } from 'ts/types/Cleaner'
import {
  VisitActions,
  Visit,
  VisitCleanerInformation,
  NoteAttachment
} from 'ts/types/Visits'

import { RootState } from '../../redux/slices'

type State = {
  loading: boolean
  error: boolean
  visitModal: boolean
  currentVisitId: string
  visitActionsModal: boolean
  selectedVisitAction: VisitActions
  visitDetails: Visit | null
  selectedCleaner: CleanerData | null
  replacementCleaner: VisitCleanerInformation | null
  visitUpdated: boolean
  isReassign: boolean
  isPreview: boolean
  isRefetchVisit: boolean
}

export const initialState: State = {
  loading: false,
  error: false,
  visitModal: false,
  currentVisitId: '',
  visitActionsModal: false,
  selectedVisitAction: '',
  visitDetails: null,
  selectedCleaner: null,
  replacementCleaner: null,
  visitUpdated: false,
  isReassign: false,
  isPreview: true,
  isRefetchVisit: false
}

const visitSlice = createSlice({
  name: 'visitSlice',
  initialState,
  reducers: {
    errors(state) {
      state.error = true
    },
    showVisitModal(state) {
      state.visitModal = true
    },
    hideVisitModal(state) {
      state.visitModal = false
    },
    setCurrentVisitId(
      state,
      { payload: { visitId } }: { payload: { visitId: string } }
    ) {
      state.currentVisitId = visitId
    },
    clearCurrentVisitId(state) {
      state.currentVisitId = ''
    },
    showVisitActionsModal(state) {
      state.visitActionsModal = true
    },
    hideVisitActionsModal(state) {
      state.visitActionsModal = false
    },
    setSelectedVisitAction(
      state,
      { payload: { action } }: { payload: { action: VisitActions } }
    ) {
      state.selectedVisitAction = action
    },
    setVisitDetails(
      state,
      { payload: { visitDetails } }: { payload: { visitDetails: Visit | null } }
    ) {
      state.visitDetails = visitDetails
    },
    setVisitNoteAttachments(
      state,
      {
        payload: { attachments }
      }: { payload: { attachments: NoteAttachment[] | null } }
    ) {
      if (state.visitDetails?.noteAttachments) {
        state.visitDetails.noteAttachments = attachments
      }
    },
    setSelectedCleaner(
      state,
      { payload: { cleaner } }: { payload: { cleaner: CleanerData | null } }
    ) {
      state.selectedCleaner = cleaner
    },
    setReplacementCleaner(
      state,
      {
        payload: { replacementCleaner }
      }: { payload: { replacementCleaner: VisitCleanerInformation | null } }
    ) {
      state.replacementCleaner = replacementCleaner
    },
    setVisitUpdated(
      state,
      { payload: { visitUpdated } }: { payload: { visitUpdated: boolean } }
    ) {
      state.visitUpdated = visitUpdated
    },
    showReassignCleanerConfirmationModal(state) {
      state.isReassign = true
    },
    hideReassignCleanerConfirmationModal(state) {
      state.isReassign = false
    },
    setPreviewVisit(state, { payload }: { payload: boolean }) {
      state.isPreview = payload
    },
    setIsRefetchVisit(state, { payload }: { payload: boolean }) {
      state.isRefetchVisit = payload
    }
  }
})

export const loadingSelector = (state: RootState) => state.visitReducer.loading

export const errorsSelector = (state: RootState) => state.visitReducer.error

export const visitModalSelector = (state: RootState) =>
  state.visitReducer.visitModal

export const currentVisitIdSelector = (state: RootState) =>
  state.visitReducer.currentVisitId

export const visitActionsModalSelector = (state: RootState) =>
  state.visitReducer.visitActionsModal

export const selectedVisitActionSelector = (state: RootState) =>
  state.visitReducer.selectedVisitAction

export const visitDetailsSelector = (state: RootState) =>
  state.visitReducer.visitDetails

export const selectedCleanerSelector = (state: RootState) =>
  state.visitReducer.selectedCleaner

export const replacementCleanerSelector = (state: RootState) =>
  state.visitReducer.replacementCleaner

export const visitUpdatedSelector = (state: RootState) =>
  state.visitReducer.visitUpdated

export const reassignCleanerModalSelector = (state: RootState) =>
  state.visitReducer.isReassign

export const isPreviewVisitSelector = (state: RootState) =>
  state.visitReducer.isPreview

export const isRefetchVisitSelector = (state: RootState): boolean =>
  state.visitReducer.isRefetchVisit

export const noteAttachments = (state: RootState): NoteAttachment[] =>
  state.visitReducer.visitDetails?.noteAttachments || []

const { name, actions, reducer } = visitSlice
export { name, actions, reducer, actions as visitActions }
