import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/slices'

import {
  MasterNotificationByFilters_masterNotificationByFilters_masterNotifications as MasterNotification,
  MasterNotificationByFilters_masterNotificationByFilters_masterNotifications_notificationTemplates as NotificationTemplate
} from '__generated__/MasterNotificationByFilters'

type State = {
  masterNotification: MasterNotification | null
  notificationTemplate: NotificationTemplate | null
}

export const initialState: State = {
  masterNotification: null,
  notificationTemplate: null
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    fetchSuccess() {},
    fetchFailure() {},
    setMasterNotification(
      state,
      {
        payload: { masterNotification }
      }: { payload: { masterNotification: MasterNotification | null } }
    ) {
      state.masterNotification = masterNotification
    }
  }
})

export const masterNotificationSelector = (state: RootState) =>
  state.notificationReducer.masterNotification

export const { name, actions, reducer } = notificationSlice
