import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../redux/slices'
type State = {
  showVoucherModal: boolean
  voucherId?: string | null
}

export const initialState: State = {
  showVoucherModal: false,
  voucherId: undefined
}

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setShowVoucherModal(state, action: PayloadAction<boolean>): void {
      state.showVoucherModal = action.payload
    },
    setVoucherId(
      state,
      action: PayloadAction<string | undefined | null>
    ): void {
      state.voucherId = action.payload
    }
  }
})

export const showVoucherModalSelector = (state: RootState): boolean =>
  state.voucherReducer.showVoucherModal

export const voucherIdSelector = (
  state: RootState
): string | null | undefined => state.voucherReducer.voucherId

const { name, actions, reducer } = voucherSlice

export { name, actions, reducer, actions as voucherActions }
