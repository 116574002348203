import { createSlice } from '@reduxjs/toolkit'
import { Payslip } from 'ts/types/Payroll'

import { RootState } from '../../redux/slices'
import { PayrollListValues } from './lists/PayrollList.screen'

export type PaymentStatus = 'PAID' | 'UNPAID' | 'OVERPAID' | 'UNDERPAID'

export type PayrollTransaction = {
  id: string
  amount: number
  type?: 'payroll' | 'payment'
  payrollMonth?: string
  paymentDate?: string
  transferMethod?: string
  transferRef?: string
}

export type PayrollValues = {
  id: string
  workerId: string
  name: string
  nationality: string
  employmentType: string
  grossWage: number
  netWage: number
  isConfirmed: boolean
  paymentStatus: PaymentStatus
  payslips: Payslip[]
  billingAccountId: string
  billingCycleId: string
  outstandingPayment: number
  fromDate: string
  toDate: string
  manager: string
  csvUrlToken: string
  acknowledgeStatus: 'Yes' | 'Pending'
}

type State = {
  generatedPayrollView: boolean
  allPayroll: PayrollListValues[]
  currentPayroll: PayrollListValues | null
  payrollId: string
  actionedPayroll: PayrollListValues | null
  isSelectAll: boolean
  selectedPayrolls: string[]
  loading: boolean
  error: boolean
  viewPayrollModal: boolean
  regeneratePayrollModal: boolean
  confirmPayrollModal: boolean
  isPayrollUpdate: boolean
  isTransactionsUpdate: boolean
  completedSessionsModal: boolean
  page: number
}

export const initialState: State = {
  allPayroll: [],
  currentPayroll: null,
  actionedPayroll: null,
  generatedPayrollView: true,
  isSelectAll: false,
  selectedPayrolls: [],
  loading: false,
  error: false,
  viewPayrollModal: false,
  regeneratePayrollModal: false,
  confirmPayrollModal: false,
  isPayrollUpdate: false,
  isTransactionsUpdate: false,
  completedSessionsModal: false,
  page: 0,
  payrollId: ''
}

const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    errors(state) {
      state.error = true
    },
    setSelectedPayrollTab(state) {
      state.generatedPayrollView = !state.generatedPayrollView
      state.selectedPayrolls = []
      state.isSelectAll = false
    },
    setCurrentPayroll(
      state,
      {
        payload: { currentPayroll }
      }: { payload: { currentPayroll: PayrollListValues | null } }
    ) {
      state.currentPayroll = currentPayroll
    },
    setActionedPayroll(
      state,
      {
        payload: { actionedPayroll }
      }: { payload: { actionedPayroll: PayrollListValues | null } }
    ) {
      state.actionedPayroll = actionedPayroll
    },
    openViewPayrollModal(state) {
      state.viewPayrollModal = true
    },
    closeViewPayrollModal(state) {
      state.viewPayrollModal = false
    },
    openRegeneratePayrollModal(state) {
      state.regeneratePayrollModal = true
    },
    closeRegeneratePayrollModal(state) {
      state.regeneratePayrollModal = false
    },
    openConfirmPayrollModal(state) {
      state.confirmPayrollModal = true
    },
    closeConfirmPayrollModal(state) {
      state.confirmPayrollModal = false
    },
    openCompletedSessionsModal(state) {
      state.completedSessionsModal = true
    },
    closeCompletedSessionsModal(state) {
      state.completedSessionsModal = false
    },
    setSelectAll(state) {
      state.isSelectAll = true
    },
    clearSelectAll(state) {
      state.isSelectAll = false
    },
    addSelectedPayroll(
      state,
      { payload: { payrollID } }: { payload: { payrollID: string } }
    ) {
      state.selectedPayrolls = [...state.selectedPayrolls, payrollID]
    },
    removeSelectedPayroll(
      state,
      { payload: { payrollID } }: { payload: { payrollID: string } }
    ) {
      state.selectedPayrolls = state.selectedPayrolls.filter(
        (id) => id !== payrollID
      )
    },
    clearSelectedPayrolls(state) {
      state.selectedPayrolls = []
    },
    setAllPayroll(
      state,
      { payload: { payrolls } }: { payload: { payrolls: PayrollListValues[] } }
    ) {
      state.allPayroll = payrolls
    },
    setIsPayrollUpdate(
      state,
      {
        payload: { isPayrollUpdate }
      }: { payload: { isPayrollUpdate: boolean } }
    ) {
      state.isPayrollUpdate = isPayrollUpdate
    },
    setPage(state, { payload: pageCounting }: { payload: number }) {
      state.page = pageCounting
    },
    setIsTransactionsUpdate(
      state,
      {
        payload: { isTransactionsUpdate }
      }: { payload: { isTransactionsUpdate: boolean } }
    ) {
      state.isTransactionsUpdate = isTransactionsUpdate
    },
    setPayrollId(state, { payload: idPayroll }: { payload: string }) {
      state.payrollId = idPayroll
    }
  }
})

export const errorsSelector = (state: RootState): boolean =>
  state.payrollReducer.error

export const payrollGeneratedViewSelector = (state: RootState): boolean =>
  state.payrollReducer.generatedPayrollView

export const displayedPayrollSelector = (
  state: RootState
): PayrollListValues[] => state.payrollReducer.allPayroll

export const currentPayrollSelector = (
  state: RootState
): PayrollListValues | null => state.payrollReducer.currentPayroll

export const viewPayrollModalSelector = (state: RootState): boolean =>
  state.payrollReducer.viewPayrollModal

export const completedSessionsModalSelector = (state: RootState): boolean =>
  state.payrollReducer.completedSessionsModal

export const selectAllSelector = (state: RootState): boolean =>
  state.payrollReducer.isSelectAll

export const selectedPayrollsSelector = (state: RootState): string[] =>
  state.payrollReducer.selectedPayrolls

export const regenerateModalSelector = (state: RootState): boolean =>
  state.payrollReducer.regeneratePayrollModal

export const confirmModalSelector = (state: RootState): boolean =>
  state.payrollReducer.confirmPayrollModal

export const actionedPayrollSelector = (
  state: RootState
): PayrollListValues | null => state.payrollReducer.actionedPayroll

export const isPayrollUpdateSelector = (state: RootState): boolean =>
  state.payrollReducer.isPayrollUpdate

export const isTransactionsUpdateSelector = (state: RootState): boolean =>
  state.payrollReducer.isTransactionsUpdate

export const allPayrollsSelector = (state: RootState): PayrollListValues[] =>
  state.payrollReducer.allPayroll

export const pageSelector = (state: RootState): number =>
  state.payrollReducer.page

export const payrollIdSelector = (state: RootState) =>
  state.payrollReducer.payrollId

export const { name, actions, reducer } = payrollSlice
