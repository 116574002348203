import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../redux/slices'

type State = {
  generatedInvoiceView: boolean
  currentInvoiceId: string | null
  isSelectAll: boolean
  selectedInvoices: string[]
  loading: boolean
  viewInvoiceModal: boolean
  regenerateInvoiceModal: boolean
  confirmInvoiceModal: boolean
  deleteInvoiceModal: boolean
  sendInvoiceModal: boolean
  paymentStatusFilter: string[]
  page: number
}

export const initialState: State = {
  currentInvoiceId: null,
  generatedInvoiceView: true,
  isSelectAll: false,
  selectedInvoices: [],
  loading: false,
  viewInvoiceModal: false,
  regenerateInvoiceModal: false,
  confirmInvoiceModal: false,
  deleteInvoiceModal: false,
  sendInvoiceModal: false,
  paymentStatusFilter: ['ALL STATUS'],
  page: 0
}

export type InvoiceModalType =
  | 'viewInvoiceModal'
  | 'regenerateInvoiceModal'
  | 'confirmInvoiceModal'
  | 'deleteInvoiceModal'
  | 'sendInvoiceModal'

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setSelectedInvoiceTab(state) {
      state.generatedInvoiceView = !state.generatedInvoiceView
      state.selectedInvoices = []
      state.isSelectAll = false
      state.paymentStatusFilter = ['ALL STATUS']
    },
    setCurrentInvoiceId(
      state,
      action: PayloadAction<{ currentInvoiceId: string | null }>
    ) {
      state.currentInvoiceId = action.payload.currentInvoiceId
    },
    setInvoiceModal(
      state,
      action: PayloadAction<{
        type: InvoiceModalType
        isOpen: boolean
      }>
    ) {
      const { type, isOpen } = action.payload
      state[type] = isOpen
    },
    setSelectAll(state) {
      state.isSelectAll = true
    },
    clearSelectAll(state) {
      state.isSelectAll = false
    },
    addSelectedInvoice(
      state,
      { payload: { invoiceID } }: { payload: { invoiceID: string } }
    ) {
      state.selectedInvoices = [...state.selectedInvoices, invoiceID]
    },
    removeSelectedInvoice(
      state,
      { payload: { invoiceID } }: { payload: { invoiceID: string } }
    ) {
      state.selectedInvoices = state.selectedInvoices.filter(
        (id) => id !== invoiceID
      )
    },
    clearSelectedInvoices(state) {
      state.selectedInvoices = []
    },
    setPage(state, { payload: pageCounting }: { payload: number }) {
      state.page = pageCounting
    },
    setPaymentStatusFilter(
      state,
      { payload: { filterStatus } }: { payload: { filterStatus: string[] } }
    ) {
      if (filterStatus.indexOf('ALL STATUS') > -1) {
        filterStatus.splice(0, 1)
      }

      if (
        !filterStatus.length ||
        filterStatus.find((value) => value === 'ALL STATUS') === 'ALL STATUS'
      ) {
        state.paymentStatusFilter = ['ALL STATUS']
      } else {
        state.paymentStatusFilter = filterStatus
      }
    }
  }
})

export const invoiceGeneratedViewSelector = (state: RootState): boolean =>
  state.invoiceReducer.generatedInvoiceView

export const currentInvoiceIdSelector = (state: RootState): string | null =>
  state.invoiceReducer.currentInvoiceId

export const viewInvoiceModalSelector = (state: RootState): boolean =>
  state.invoiceReducer.viewInvoiceModal

export const regenerateInvoiceModalSelector = (state: RootState): boolean =>
  state.invoiceReducer.regenerateInvoiceModal

export const confirmInvoiceModalSelector = (state: RootState): boolean =>
  state.invoiceReducer.confirmInvoiceModal

export const deleteInvoiceModalSelector = (state: RootState): boolean =>
  state.invoiceReducer.deleteInvoiceModal

export const sendInvoiceModalSelector = (state: RootState): boolean =>
  state.invoiceReducer.sendInvoiceModal

export const selectAllSelector = (state: RootState): boolean =>
  state.invoiceReducer.isSelectAll

export const selectedInvoicesSelector = (state: RootState): string[] =>
  state.invoiceReducer.selectedInvoices

export const paymentStatusFilterSelector = (state: RootState): string[] =>
  state.invoiceReducer.paymentStatusFilter

export const pageSelector = (state: RootState): number =>
  state.invoiceReducer.page

export const { name, actions, reducer } = invoiceSlice
