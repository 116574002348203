import DialogInfo from '@luce/ui-kit/components/organism/DialogInfo'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions, modalSelector } from '../../redux/slices/modalSlice'

interface Props {}

const ProfileModal: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const modal = useSelector(modalSelector)

  const handleClose = () => {
    dispatch(actions.hideModal())
  }

  return (
    <DialogInfo
      open={modal}
      onClose={handleClose}
      onConfirm={handleClose}
      title="User Profile"
      contentText="User profile info"
      confirmLabel="Close"
    />
  )
}

export default ProfileModal
