import { User } from 'context/AuthContext'

export interface UserSession {
  user?: User | null
  jwtToken: string | null
  jwtExpiry: number | null
}

export const userSessionKey = 'USER_DATA_INTERNAL'
export const JWTTokenKey = 'jwtTokenInternal'
export const JWTTokenExpiryKey = 'JWTTokenExpiryInternal'
export const refreshTokenKey = 'refreshTokenInternal'

const restoreSession = (): UserSession => {
  const data = window.localStorage.getItem(userSessionKey)
  const jwtToken = window.localStorage.getItem(JWTTokenKey)
  const jwtTokenExpiry = window.localStorage.getItem(JWTTokenExpiryKey)
  const userData: User | null = data ? JSON.parse(data) : null
  console.log(
    `Restoring User Session, user data: ${userData ? 'exists' : 'empty'}`
  )

  return {
    user: userData
      ? {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          avatarUrl: userData.avatarUrl
        }
      : null,
      jwtToken,
      jwtExpiry: jwtTokenExpiry ? parseInt(jwtTokenExpiry) : null
  }
}

let session: UserSession | undefined

const logout = (): UserSession => {
  window.localStorage.removeItem(userSessionKey)
  session = restoreSession()
  return session
}

const login = (user: User, jwtToken: string, jwtExpiry: number): UserSession => {
  console.log('Write session to storage')
  const userData = JSON.stringify(user)
  window.localStorage.setItem(userSessionKey, userData)
  window.localStorage.setItem(JWTTokenKey, jwtToken)
  window.localStorage.setItem(JWTTokenExpiryKey, `${jwtExpiry}000`) // add extra zero due to difference Date.valueOf and ruby Date.to_i
  session = {
    user: Object.assign({}, user),
    jwtToken,
    jwtExpiry
  }
  return session
}

const currentSession = (): UserSession => {
  session = restoreSession()
  return session
}

export default {
  currentSession,
  login,
  logout
}
