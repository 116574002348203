import { useEffect } from 'react'
import { useQueryParam } from 'utils/hooks'

export function useDebouncedQueryParams(value, paramKey, delay = 1500): string {
  const [param, updateParam] = useQueryParam(paramKey, '')

  useEffect(() => {
    const handler = setTimeout(() => {
      updateParam(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return param
}
