import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/slices'
import { CleanerListContract } from 'ts/types/Cleaner'

type CleanerContractsAction = {
  payload: {
    cleanerContracts: CleanerListContract[] | null
  }
}

type CurrentContractAction = {
  payload: {
    currentCleanerContract: CleanerListContract | null
  }
}

type FilteredContractsAction = {
  payload: {
    filteredCleanerContracts: CleanerListContract[]
  }
}

type State = {
  currentCleanerContract: CleanerListContract | null
  cleanerContracts: CleanerListContract[] | null // TODO: this look unnecessary because it only used for storing all contract
  filteredCleanerContracts: CleanerListContract[] // TODO: this state the real data used for selecting worker contract
  filteredWorkerSkillIds: string[] | null
  assignModal?: boolean
  unassignModal?: boolean
}

export const initialState: State = {
  currentCleanerContract: null,
  cleanerContracts: null, // TODO: could be empty array
  filteredCleanerContracts: [],
  filteredWorkerSkillIds: null,
  assignModal: false,
  unassignModal: false
}

const searchCleanerSlice = createSlice({
  name: 'searchCleaner',
  initialState,
  reducers: {
    setCurrentCleanerContract(state, action: CurrentContractAction): void {
      // TODO: here we also can specify the filtered contract and handle the filter param here, so we only dispatch single reducer
      state.currentCleanerContract = action.payload.currentCleanerContract
    },
    setCleanerContracts(state, action: CleanerContractsAction): void {
      state.cleanerContracts = action.payload.cleanerContracts
    },
    setFilteredCleanerContracts(state, action: FilteredContractsAction): void {
      state.filteredCleanerContracts = action.payload.filteredCleanerContracts
      const currentContractInFilteredContracts = action.payload.filteredCleanerContracts.find(
        ({ id }) => id === state.currentCleanerContract?.id
      )
      if (!currentContractInFilteredContracts) {
        state.currentCleanerContract = null
      }
    },
    setWorkerSkillIds(
      state,
      { payload }: PayloadAction<{ workerSkillIds: string[] | null }>
    ) {
      state.filteredWorkerSkillIds = payload.workerSkillIds
    },
    clearWorkerSkillIds(state) {
      state.filteredWorkerSkillIds = null
    },
    showAssignModal(state) {
      state.assignModal = true
    },
    hideAssignModal(state) {
      state.assignModal = false
    },
    showUnassignModal(state) {
      state.unassignModal = true
    },
    hideUnassignModal(state) {
      state.unassignModal = false
    },
    cleanupContractData(state) {
      state.currentCleanerContract = null
      state.cleanerContracts = null
      state.filteredCleanerContracts = []
    }
  }
})

export const currentCleanerContractSelector = (state: RootState) =>
  state.searchCleanerReducer.currentCleanerContract

export const cleanerContractsSelector = (state: RootState) =>
  state.searchCleanerReducer.cleanerContracts

// TODO: explore the usage of this selector, remove if not used anymore
export const filteredCleanerContractsSelector = (state: RootState) =>
  state.searchCleanerReducer.filteredCleanerContracts

export const assignModalSelector = (state: RootState) =>
  state.searchCleanerReducer.assignModal

export const unassignModalSelector = (state: RootState) =>
  state.searchCleanerReducer.unassignModal

export const filteredWorkerSkillIdsSelector = (state: RootState) =>
  state.searchCleanerReducer.filteredWorkerSkillIds

export const { name, actions, reducer } = searchCleanerSlice
