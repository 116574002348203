import {
  createAction,
  createSlice,
  PayloadAction,
  createSelector
} from '@reduxjs/toolkit'
import { VisitStatusEnum } from '__generated__/globalTypes'
import { EventType, TimeSlotType } from 'components/calendar/Calendar.screen'
import { CleanerData } from 'ts/types/Cleaner'
import { formatDate } from 'utils/date'

import { RootState } from '../../redux/slices'
import { mapCleanerToFreeSlots } from './lib'

export type SelectedCleaner = {
  id: string
  name: string
  workerRating: number
  scheduleVariantId?: string | null
  avatarUrl?: string | null
}

const initialVisitStatusFilter: { [key in VisitStatusEnum]: boolean } = {
  [VisitStatusEnum.SCHEDULED]: true,
  [VisitStatusEnum.EXPIRED]: true,
  [VisitStatusEnum.COMPLETED]: true,
  [VisitStatusEnum.CANCELLED_BY_CLIENT]: false,
  [VisitStatusEnum.CANCELLED_BY_OPS]: false,
  [VisitStatusEnum.CANCELLED_BY_WORKER]: false,
  [VisitStatusEnum.MISSED_BY_WORKER]: false,
  [VisitStatusEnum.PENDING_CLIENT_SCHEDULE]: false,
  [VisitStatusEnum.FINISHED]: false,
  [VisitStatusEnum.STARTED]: false
}

export type SchedulerVisitStatusFilter = typeof initialVisitStatusFilter

export type FreeSlotData = EventType & TimeSlotType
export type FreeSlotCleaners = Record<string, FreeSlotData[]>

type State = {
  queriedCleaners: CleanerData[]
  selectedCleaner: SelectedCleaner | null
  freeslotCleaners: FreeSlotCleaners
  clearSearch: boolean
  loading: boolean
  visitStatusFilter: SchedulerVisitStatusFilter
  expandView: boolean
  currentDate: string
  queriedCleanerLimit: number
  refreshScheduler: boolean
}

const INREMENT_QUERIED_CLEANER_LIMIT = 10
export const INITIAL_QUERIED_CLEANER_LIMIT = 10

export const initialState: State = {
  queriedCleaners: [],
  selectedCleaner: null,
  freeslotCleaners: {},
  clearSearch: false,
  loading: false,
  visitStatusFilter: initialVisitStatusFilter,
  expandView: true,
  currentDate: formatDate(new Date()),
  queriedCleanerLimit: INITIAL_QUERIED_CLEANER_LIMIT,
  refreshScheduler: false
}

type SetQueriedCleaners = {
  cleaners: CleanerData[]
}

type Loading = {
  loading: boolean
}

type WorkerSchedule = {
  id: string
  name: string
  workerRating: number
  scheduleVariantId?: string | null
  avatarUrl?: string | null
}

export const setVisitStatusFilterAction = createAction<SchedulerVisitStatusFilter>(
  'schedulerCleaners/setVisitStatusFilter'
)

export const setQueriedCleanersAction = createAction<SetQueriedCleaners>(
  'schedulerCleaners/setQueriedCleaners'
)

const schedulerCleanersSlice = createSlice({
  name: 'schedulerCleaners',
  initialState,
  reducers: {
    selectCleaner(state, action: PayloadAction<WorkerSchedule>) {
      const {
        id,
        name,
        workerRating,
        scheduleVariantId,
        avatarUrl
      } = action.payload
      state.selectedCleaner = {
        id,
        name,
        workerRating,
        scheduleVariantId,
        avatarUrl
      }

      // set the current date to first combination to focus calendar
      if (scheduleVariantId) {
        const [firstCombination] = state.freeslotCleaners[scheduleVariantId]
        state.currentDate = formatDate(new Date(firstCombination.startDate))
      }
    },
    clearSelectedCleaner(state) {
      state.selectedCleaner = null
    },
    clearCleaners(state) {
      state.queriedCleaners = []
      state.queriedCleanerLimit = INITIAL_QUERIED_CLEANER_LIMIT
      state.selectedCleaner = null
    },
    setClearSearch(
      state,
      { payload: { action } }: { payload: { action: boolean } }
    ) {
      state.clearSearch = action
    },
    setQueriedCleaners(state, action: PayloadAction<SetQueriedCleaners>) {
      const { cleaners } = action.payload
      state.freeslotCleaners = cleaners.reduce<FreeSlotCleaners>(
        (allFreeslots, cleaner) => {
          allFreeslots[cleaner.scheduleVariantId!] = mapCleanerToFreeSlots(
            cleaner
          )
          return allFreeslots
        },
        {}
      )

      state.queriedCleaners = cleaners.map((cleaner) => {
        delete cleaner.combinations
        return cleaner
      })
    },
    setLoading(state, action: PayloadAction<Loading>) {
      const { loading } = action.payload
      state.loading = loading
    },
    setVisitStatusFilter(
      state,
      { payload: visitStatusFilter }: PayloadAction<SchedulerVisitStatusFilter>
    ) {
      state.visitStatusFilter = visitStatusFilter
    },
    toggleExpandView(state) {
      state.expandView = !state.expandView
    },

    setCurrentDate(
      state,
      { payload: { currentDate } }: { payload: { currentDate: string } }
    ) {
      state.currentDate = currentDate
    },
    incrementQueriedCleanerLimit(state) {
      state.queriedCleanerLimit += INREMENT_QUERIED_CLEANER_LIMIT
    },
    resetQueriedCleanerLimit(state) {
      state.queriedCleanerLimit += INITIAL_QUERIED_CLEANER_LIMIT
    },
    setRefreshScheduler(
      state,
      {
        payload: { refreshScheduler }
      }: PayloadAction<{ refreshScheduler: boolean }>
    ) {
      state.refreshScheduler = refreshScheduler
    }
  }
})

export const selectedCleanerSelector = createSelector(
  (state: RootState) => state.schedulerCleanersReducer.selectedCleaner,
  (selectedCleaner) => selectedCleaner
)

export const clearSearchSelector = (state: RootState): boolean =>
  state.schedulerCleanersReducer.clearSearch

export const queriedCleanerLimitSelector = createSelector(
  (state: RootState) => state.schedulerCleanersReducer.queriedCleanerLimit,
  (queriedCleanerLimit) => queriedCleanerLimit
)

export const queriedCleanersSelector = createSelector(
  [
    (state: RootState) => state.schedulerCleanersReducer.queriedCleaners,
    queriedCleanerLimitSelector
  ],
  (queriedCleaners, queriedCleanerLimit) =>
    queriedCleaners.slice(0, queriedCleanerLimit)
)

export const totalQueriedCleanersSelector = (state: RootState): number =>
  state.schedulerCleanersReducer.queriedCleaners.length

export const schedulerLoadingSelector = (state: RootState): boolean =>
  state.schedulerCleanersReducer.loading

const freeslotCleanersSelector = createSelector(
  (state: RootState) => state.schedulerCleanersReducer.freeslotCleaners,
  (freeslotCleaners) => freeslotCleaners
)

export const allWorkerFreeSlotSelector = createSelector(
  [freeslotCleanersSelector],
  (freeslotCleaners) =>
    Object.values(freeslotCleaners).reduce<FreeSlotData[]>(
      (allEvents, freeslots) => [...allEvents, ...freeslots],
      []
    )
)

export const cleanerFreeSlotsSelector = createSelector(
  [selectedCleanerSelector, freeslotCleanersSelector],
  (selectedCleaner, freeslotCleaners) => {
    if (
      selectedCleaner?.scheduleVariantId &&
      freeslotCleaners[selectedCleaner.scheduleVariantId]
    ) {
      return freeslotCleaners[selectedCleaner.scheduleVariantId]
    }

    return []
  }
)

export const currentDateSelector = createSelector(
  (state: RootState) => state.schedulerCleanersReducer.currentDate,
  (currentDate) => currentDate
)

export const visitStatusFilterSelector = createSelector(
  (state: RootState): SchedulerVisitStatusFilter =>
    state.schedulerCleanersReducer.visitStatusFilter,
  (visitStatusFilter) => visitStatusFilter
)

export const selectedVisitStatusFilterSelector = createSelector(
  [visitStatusFilterSelector],
  (visitStatusFilter) => {
    const selectedStatus = Object.keys(visitStatusFilter).reduce<
      VisitStatusEnum[]
    >((statuses, status) => {
      if (visitStatusFilter[status]) {
        statuses.push(status as VisitStatusEnum)
      }
      return statuses
    }, [])

    return selectedStatus
  }
)

export const expandViewSelector = (state: RootState): boolean =>
  state.schedulerCleanersReducer.expandView

export const refreshSchedulerSelector = (state: RootState) => {
  return state.schedulerCleanersReducer.refreshScheduler
}

export const { name, actions, reducer } = schedulerCleanersSlice
