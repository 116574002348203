import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PackageDetailFragment } from '__generated__/PackageDetailFragment'
import {
  WorkerDepartmentEnum,
  ServiceTypeEnum,
  PackageServiceTypeEnum,
  PackageDetailSessionEnum
} from '__generated__/globalTypes'
import { ShortDay } from 'ts/types/Days'

import { RootState } from '../../redux/slices'
import { BookingType } from './BookingPage.container'
import { groupTimeSlotByDate, PackageFrequencyGroupEnum } from './lib'
import { SERVICE_TYPE_UNIT_TYPES } from './ui-component/AirconServiceUnitInput'

export enum BookingStepEnum {
  SELECT_SERVICE = 'SELECT_SERVICE',
  BOOKING_DETAIL = 'BOOKING_DETAIL',
  SELECT_SLOT = 'SELECT_SLOT',
  SERVICE_INFO = 'SERVICE_INFO',
  CONFIRMATION = 'CONFIRMATION',
  COMPLETE_BOOKING = 'COMPLETE_BOOKING'
}

export type BookingDetailFormValues = {
  frequency: PackageFrequencyGroupEnum
  postalCode: string
  fullAddress?: string
  addressId: string
}

export type ServiceInfoFormValues = {
  contactId: string
  name: string
  phoneNumber: string
  email: string
  addressId: string
  postalCode: string
  accessMode: string
  accessInstructions: string
  packageNotes: string
}

export type Cleaner = {
  id: string
  name: string
  completedJobs: number
}

export type TimeSlot = {
  id: string
  slotId: string
  date: string
  day: ShortDay
  partOfDay: 'Morning' | 'Evening' | 'Afternoon'
  startTime: string
  endTime: string
  averageTravelTime: number
  workerId: string
  discount?: number
  sessionValue?: number
  rateValue?: number
  workerName?: string
}

export type AppliedPromo = {
  appliedPromoCode: string
  computedDiscount: number
}

export type AvailableClientAirconService =
  | ServiceTypeEnum.GENERAL_SERVICING
  | ServiceTypeEnum.CHEMICAL_WASH
  | ServiceTypeEnum.CHEMICAL_OVERHAUL
  | ServiceTypeEnum.CONDENSER_WASH
  | ServiceTypeEnum.REPAIR_DIAGNOSTIC
  | ServiceTypeEnum.JET_WASH

type State = {
  bookingStepKey: BookingStepEnum
  // bookingFormStep: BookingStepNumber
  department: WorkerDepartmentEnum
  serviceTypes: ServiceTypeEnum[]
  bookingDetailForm: BookingDetailFormValues
  serviceInfoForm: ServiceInfoFormValues
  availableSlots: TimeSlot[]
  selectedSlot: TimeSlot | null
  packageDetails: PackageDetailFragment[]
  promo: AppliedPromo
}

const HOME_CLEANING_BOOKING_ORDER = [
  BookingStepEnum.BOOKING_DETAIL,
  BookingStepEnum.SELECT_SLOT,
  BookingStepEnum.SERVICE_INFO,
  BookingStepEnum.CONFIRMATION
  // BookingStepEnum.COMPLETE_BOOKING
]
const AIRCON_BOOKING_ORDER = [
  BookingStepEnum.SELECT_SERVICE,
  BookingStepEnum.BOOKING_DETAIL,
  BookingStepEnum.SELECT_SLOT,
  BookingStepEnum.SERVICE_INFO,
  BookingStepEnum.CONFIRMATION
  // BookingStepEnum.COMPLETE_BOOKING
]

export const BOOKING_ORDER: Record<WorkerDepartmentEnum, BookingStepEnum[]> = {
  [WorkerDepartmentEnum.HOME_CLEANING]: HOME_CLEANING_BOOKING_ORDER,
  [WorkerDepartmentEnum.AIRCON]: AIRCON_BOOKING_ORDER,
  [WorkerDepartmentEnum.OFFICE_CLEANING]: [],
  [WorkerDepartmentEnum.HOME_BEAUTY]: []
}

const findStepBooking = (
  department: WorkerDepartmentEnum,
  currentStep: BookingStepEnum,
  type: 'prev' | 'next'
): BookingStepEnum => {
  const steps = BOOKING_ORDER[department]
  const currentIndex = steps.findIndex((step) => step === currentStep)
  return type === 'next' ? steps[currentIndex + 1] : steps[currentIndex - 1]
}

export const initialState: State = {
  bookingStepKey: BookingStepEnum.SELECT_SERVICE,
  department: WorkerDepartmentEnum.HOME_CLEANING,
  serviceTypes: [],
  bookingDetailForm: {
    frequency: PackageFrequencyGroupEnum.AD_HOC,
    postalCode: '',
    addressId: ''
  },
  serviceInfoForm: {
    contactId: '',
    name: '',
    phoneNumber: '',
    email: '',
    addressId: '',
    postalCode: '',
    accessMode: '',
    accessInstructions: '',
    packageNotes: ''
  },
  availableSlots: [],
  selectedSlot: null,
  packageDetails: [],
  promo: {
    appliedPromoCode: '',
    computedDiscount: 0
  }
}

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    initBookingType(state, { payload }: { payload: BookingType }) {
      if (payload === 'aircon') {
        state.bookingStepKey = BookingStepEnum.SELECT_SERVICE
        state.department = WorkerDepartmentEnum.AIRCON
        state.serviceTypes = []
      } else {
        state.bookingStepKey = BookingStepEnum.BOOKING_DETAIL
        state.department = WorkerDepartmentEnum.HOME_CLEANING
        state.serviceTypes = [ServiceTypeEnum.HOME_CLEANING]
      }

      state.bookingDetailForm = {
        frequency: PackageFrequencyGroupEnum.AD_HOC,
        postalCode: '',
        addressId: ''
      }
      state.packageDetails = []
    },
    setServiceType(
      state,
      { payload }: PayloadAction<{ serviceType: ServiceTypeEnum[] }>
    ) {
      state.serviceTypes = payload.serviceType
    },
    setBookingDetailForm(
      state,
      {
        payload
      }: {
        payload: {
          formValues: BookingDetailFormValues
          packageDetails: PackageDetailFragment[]
        }
      }
    ) {
      state.packageDetails = payload.packageDetails
      state.bookingDetailForm = payload.formValues
    },
    setServiceInfoForm(
      state,
      {
        payload: { formValues }
      }: { payload: { formValues: ServiceInfoFormValues } }
    ) {
      state.serviceInfoForm = formValues
    },

    setBookingStep(state, action: { payload: BookingStepEnum }) {
      state.bookingStepKey = action.payload
    },

    nextStep(state) {
      state.bookingStepKey = findStepBooking(
        state.department,
        state.bookingStepKey,
        'next'
      )
    },

    prevStep(state) {
      const step = findStepBooking(
        state.department,
        state.bookingStepKey,
        'prev'
      )
      state.bookingStepKey = step
      const stepIndex = BOOKING_ORDER[state.department].findIndex(
        (stepKey) => stepKey === step
      )
      if (stepIndex === 0) {
        state.selectedSlot = null
        state.packageDetails = []
      }
    },

    setAvailableSlots(
      state,
      {
        payload: { availableSlots }
      }: { payload: { availableSlots: TimeSlot[] } }
    ) {
      state.availableSlots = availableSlots
    },

    setSelectedSlot(
      state,
      {
        payload: { selectedSlot }
      }: { payload: { selectedSlot: TimeSlot | null } }
    ) {
      state.selectedSlot = selectedSlot
      if (
        state.department === WorkerDepartmentEnum.HOME_CLEANING &&
        selectedSlot
      ) {
        state.packageDetails = state.packageDetails.map((packageDetail) => {
          const sessionSlot =
            selectedSlot.partOfDay === 'Evening'
              ? PackageDetailSessionEnum.EVENING
              : PackageDetailSessionEnum.DAY

          if (packageDetail.session === sessionSlot) {
            packageDetail.serviceBillingValue = selectedSlot.sessionValue!
            packageDetail.unitValue = selectedSlot.rateValue!
          }

          return packageDetail
        })
      }
    },

    clearSelectedSlot(state) {
      state.selectedSlot = null
    },

    clearAvailableSlots(state) {
      state.availableSlots = []
    },

    cleanupForm(
      state,
      { payload: resetAll = false }: PayloadAction<boolean | undefined>
    ) {
      state.department = WorkerDepartmentEnum.HOME_CLEANING
      state.serviceTypes = []
      state.bookingDetailForm = {
        frequency: PackageFrequencyGroupEnum.AD_HOC,
        postalCode: '',
        addressId: ''
      }
      state.serviceInfoForm = {
        contactId: '',
        name: '',
        phoneNumber: '',
        email: '',
        addressId: '',
        postalCode: '',
        accessMode: '',
        accessInstructions: '',
        packageNotes: ''
      }
      state.availableSlots = []
      state.selectedSlot = null
      state.packageDetails = []
      state.promo = {
        appliedPromoCode: '',
        computedDiscount: 0
      }
      if (resetAll) {
        state.bookingStepKey = BookingStepEnum.SELECT_SERVICE
      }
    },

    setAppliedPromo(state, action: PayloadAction<AppliedPromo>) {
      const { appliedPromoCode, computedDiscount } = action.payload

      state.promo = {
        appliedPromoCode,
        computedDiscount
      }
    }
  }
})

export const bookingStepKeySelector = (state: RootState) =>
  state.bookingReducer.bookingStepKey

export const bookingDetailFormValuesSelector = (state: RootState) =>
  state.bookingReducer.bookingDetailForm

export const bookingDepartmentSelector = (state: RootState) =>
  state.bookingReducer.department

export const bookingStepIndexSelector = createSelector(
  bookingStepKeySelector,
  bookingDepartmentSelector,
  (step, department) => {
    const stepIndex = BOOKING_ORDER[department].findIndex(
      (stepKey) => stepKey === step
    )

    return stepIndex
  }
)

export const isInitialBookingStepSelector = createSelector(
  bookingStepKeySelector,
  bookingDepartmentSelector,
  (step, department) => {
    const stepIndex = BOOKING_ORDER[department].findIndex(
      (stepKey) => stepKey === step
    )

    return stepIndex === 0
  }
)

// TODO: Need to find a better name when currently setting booking preference
// like set the service type and detailing the booking
export const isSelectingBookingDetailSelector = (state: RootState) =>
  [BookingStepEnum.SELECT_SERVICE, BookingStepEnum.BOOKING_DETAIL].includes(
    state.bookingReducer.bookingStepKey
  )

export const bookingServiceTypeSelector = (state: RootState) =>
  state.bookingReducer.serviceTypes

export const airconServiceTypeSelector = (
  state: RootState
): [
  AvailableClientAirconService,
  ServiceTypeEnum.JET_WASH | ServiceTypeEnum.CHEMICAL_WASH | undefined
] => {
  const [serviceType, upgradedServiceType] = state.bookingReducer
    .serviceTypes as [
    AvailableClientAirconService,
    ServiceTypeEnum.JET_WASH | ServiceTypeEnum.CHEMICAL_WASH | undefined
  ]

  return [serviceType, upgradedServiceType] // TODO: remove later for upgraded service
}

export const bookingFrequencySelector = createSelector(
  (state: RootState) => state.bookingReducer.bookingDetailForm,
  (bookingDetailForm) => bookingDetailForm.frequency
)

export const bookingPackagesSelector = createSelector(
  (state: RootState) => state.bookingReducer.packageDetails,
  (state: RootState) => state.bookingReducer.selectedSlot,
  bookingDepartmentSelector,
  (packageDetails, selectedSlot, department) => {
    if (department === WorkerDepartmentEnum.HOME_CLEANING) {
      if (selectedSlot) {
        return packageDetails.filter(({ session }) => {
          const sessionSlot =
            selectedSlot.partOfDay === 'Evening'
              ? PackageDetailSessionEnum.EVENING
              : PackageDetailSessionEnum.DAY

          return session === sessionSlot
        })
      } else {
        return packageDetails.filter(
          ({ session }) => session === PackageDetailSessionEnum.DAY
        )
      }
    }

    return packageDetails
  }
)

export const lineItemIdsFindScheduleVariantSelector = createSelector(
  (state: RootState) => state.bookingReducer.packageDetails,
  bookingDepartmentSelector,
  (packageDetails, department) => {
    let packageDetailIds = packageDetails.map(({ id }) => id)
    if (department === WorkerDepartmentEnum.HOME_CLEANING) {
      // only return session DAY on Home Cleaning to find slot
      packageDetailIds = packageDetails
        .filter(({ session }) => session === PackageDetailSessionEnum.DAY)
        .map(({ id }) => id)
    }

    return packageDetailIds
  }
)

export const workerSkillIdsFindScheduleVariantSelector = createSelector(
  (state: RootState) => state.bookingReducer.packageDetails,
  (packageDetails) => {
    const workerSkillIds = packageDetails.map(
      ({ workerSkill }) => workerSkill.id
    )
    return Array.from(new Set(workerSkillIds)) // remove duplication.
  }
)

export const serviceInfoFormValuesSelector = (state: RootState) =>
  state.bookingReducer.serviceInfoForm

export const availableSlotsSelector = (state: RootState) =>
  state.bookingReducer.availableSlots

export const groupedAvailableSlotsSelector = createSelector(
  (state: RootState) => state.bookingReducer.availableSlots,
  (availableSlots) => groupTimeSlotByDate(availableSlots)
)

export const selectedSlotSelector = (state: RootState) =>
  state.bookingReducer.selectedSlot

export const totalPackageDurationSelector = createSelector(
  bookingPackagesSelector,
  (packages) =>
    packages.reduce<number>((total, packageDetail) => {
      return total + packageDetail.duration
    }, 0)
)

export const totalServiceBillingValueSelector = createSelector(
  bookingPackagesSelector,
  (packages) =>
    packages.reduce<number>((total, packageDetail) => {
      return total + packageDetail.serviceBillingValue
    }, 0)
)

export const airconUnitTypesValueSelector = createSelector(
  bookingPackagesSelector,
  airconServiceTypeSelector,
  (packages, serviceTypes) =>
    serviceTypes.reduce<Record<string, string>>(
      (group, serviceType, indexServiceType) => {
        if (serviceType) {
          const unitTypes =
            SERVICE_TYPE_UNIT_TYPES[
              (serviceType as unknown as PackageServiceTypeEnum) ??
                PackageServiceTypeEnum.GENERAL_SERVICING
            ]
          unitTypes.forEach((unitType) => {
            const packageDetail = packages.find(
              (pkg) =>
                pkg.serviceType ===
                  (serviceType as unknown as PackageServiceTypeEnum) &&
                pkg.unitType === unitType
            )
            let value = String(packageDetail?.units ?? '0')

            const primaryServiceUnit = group[`${serviceTypes[0]}_${unitType}`]
            if (indexServiceType > 0 && primaryServiceUnit) {
              value = primaryServiceUnit
            }

            group[`${serviceType}_${unitType}`] = value
          })
        }

        return group
      },
      {}
    )
)

export const promoSelector = (state: RootState) => state.bookingReducer.promo

export const { name, actions, actions: bookingActions, reducer } = bookingSlice
