import { createSlice } from '@reduxjs/toolkit'
import { MasterRewardsValues } from './MasterRewardPage.screen'
import { RootState } from 'redux/slices'

type State = {
  selectedMasterReward: MasterRewardsValues | null
}

export const initialState: State = {
  selectedMasterReward: null
}

const rewardSlice = createSlice({
  name: 'rewardSlice',
  initialState,
  reducers: {
    setSelectedMasterReward(
      state,
      {
        payload: { masterReward }
      }: { payload: { masterReward: MasterRewardsValues | null } }
    ) {
      state.selectedMasterReward = masterReward
    }
  }
})

export const selectedMasterRewardSelector = (state: RootState) =>
  state.rewardReducer.selectedMasterReward
  
const { name, actions, reducer } = rewardSlice
export { name, actions, reducer, actions as rewardActions }
