import { Redirect, Route, RouteProps } from 'react-router-dom'
import useAuth from '../../hooks/AuthHook'

interface Props extends RouteProps {
  component: any
}
const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { loadingSession, user } = useAuth() ?? {}
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <Redirect to="/login" />
        }

        if (loadingSession) {
          return null
        }

        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
