import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../redux/slices'

import { CreditTypeEnum } from '../../__generated__/globalTypes'

export type CreditAccount = {
  id: string
  balance: number
  creditType: CreditTypeEnum
}

type State = {
  selectedCreditAccount: CreditAccount | null
  withdrawModal: boolean
  isAuthorizedModalShow: boolean
  transactionId: string
  transactionBalance: number
}

export const initialState: State = {
  selectedCreditAccount: null,
  withdrawModal: false,
  isAuthorizedModalShow: false,
  transactionId: '',
  transactionBalance: 0
}

export type InvoiceModalType =
  | 'viewInvoiceModal'
  | 'regenerateInvoiceModal'
  | 'confirmInvoiceModal'
  | 'deleteInvoiceModal'
  | 'sendInvoiceModal'

const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    displayWithdrawModal(state, action: PayloadAction<{ show: boolean }>) {
      state.withdrawModal = action.payload.show
    },
    setSelectedCreditAccount(
      state,
      action: PayloadAction<CreditAccount | null>
    ) {
      state.selectedCreditAccount = action.payload
    },
    resetSelectedCreditAccount(state) {
      state.selectedCreditAccount = null
    },
    setShowAuthorizeModal(
      state,
      action: PayloadAction<{
        isAuthorizedModalShow: boolean
        transactionId: string
        transactionBalance: number
      }>
    ) {
      state.isAuthorizedModalShow = action.payload.isAuthorizedModalShow
      state.transactionId = action.payload.transactionId
      state.transactionBalance = action.payload.transactionBalance
    }
  }
})

export const withdrawModalSelector = (state: RootState): boolean =>
  state.creditReducer.withdrawModal

export const creditAccountSelector = (state: RootState): CreditAccount | null =>
  state.creditReducer.selectedCreditAccount

export const isShowAuthorizeModalShowSelector = (state: RootState): boolean =>
  state.creditReducer.isAuthorizedModalShow

export const transactionIdSelector = (state: RootState): string =>
  state.creditReducer.transactionId

export const transactionBalanceSelector = (state: RootState): number =>
  state.creditReducer.transactionBalance

export const { name, actions, reducer } = creditSlice
