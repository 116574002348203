import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/slices'
import { Client, ClientContract, ClientAddress } from 'ts/types/Client'

type State = {
  currentClient: Client | null
  contracts: ClientContract[] | null
  currentContract: ClientContract | null
  filteredContracts: ClientContract[]
  addresses: ClientAddress[] | null
  currentAddress: ClientAddress | null
}

export const initialState: State = {
  currentClient: null,
  contracts: null,
  currentContract: null,
  filteredContracts: [],
  addresses: null,
  currentAddress: null
}

const searchClientSlice = createSlice({
  name: 'searchClient',
  initialState,
  reducers: {
    setCurrentClient(
      state,
      { payload: { client } }: { payload: { client: Client | null } }
    ) {
      state.currentClient = client
    },

    setContracts(
      state,
      {
        payload: { contracts }
      }: { payload: { contracts: ClientContract[] | null } }
    ) {
      state.contracts = contracts
    },

    setCurrentContract(
      state,
      {
        payload: { currentContract }
      }: { payload: { currentContract: ClientContract | null } }
    ) {
      state.currentContract = currentContract
    },

    setFilteredContracts(
      state,
      {
        payload: { filteredContracts }
      }: { payload: { filteredContracts: ClientContract[] } }
    ) {
      state.filteredContracts = filteredContracts
    },

    setAddresses(
      state,
      {
        payload: { addresses }
      }: { payload: { addresses: ClientAddress[] | null } }
    ) {
      state.addresses = addresses
    },

    setCurrentAddress(
      state,
      {
        payload: { currentAddress }
      }: { payload: { currentAddress: ClientAddress | null } }
    ) {
      state.currentAddress = currentAddress
    }
  }
})

export const currentClientSelector = (state: RootState) =>
  state.searchClientReducer.currentClient

export const currentContractSelector = (state: RootState) =>
  state.searchClientReducer.currentContract

export const filteredContractsSelector = (state: RootState) =>
  state.searchClientReducer.filteredContracts

export const addressesSelector = (state: RootState) =>
  state.searchClientReducer.addresses

export const currentAddressSelector = (state: RootState) =>
  state.searchClientReducer.currentAddress

export const { name, actions, reducer } = searchClientSlice
