import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loginUser() {},
    logoutUser() {}
  }
})

export const { name, actions, reducer } = appSlice
