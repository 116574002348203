import { FetchResult, useMutation, MutationResult } from '@apollo/react-hooks'
import { updateWorkerTipInput } from '__generated__/globalTypes'


import {
  UpdateWorkerTipMessage,
  UpdateWorkerTipMessageVariables
} from '../../../../src/__generated__/UpdateWorkerTipMessage'

import UPDATE_WORKER_TIP from   '../__graphql__/UpdateWorkerTipMessage.graphql'

type UpdateWorkerTipResult = FetchResult<UpdateWorkerTipMessage>

export default function useUpdateWorkerTip(): [
  (input: updateWorkerTipInput) => Promise<UpdateWorkerTipResult>,
  MutationResult<UpdateWorkerTipMessage>
] {
  const [mutation, result] = useMutation<
    UpdateWorkerTipMessage,
    UpdateWorkerTipMessageVariables
  >(UPDATE_WORKER_TIP)

  function updateWorkerTip(
    input: updateWorkerTipInput
  ): Promise<UpdateWorkerTipResult> {
    return mutation({
      variables: {
        input
      }
    })
  }

  return [updateWorkerTip, result]
}

export { useUpdateWorkerTip }
