import * as Sentry from '@sentry/react'

import { getEnv, isLocal } from '../utils/helpers'

export function init(): void {
  if (isLocal()) {
    return
  }

  const { sentryDsn: dsn, env } = getEnv()

  if (dsn) {
    Sentry.init({
      environment: env,
      dsn,
      integrations: [
        // new Sentry.BrowserTracing({
        //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        // }),
        // new Sentry.Replay({
        //   maskAllText: false
        // })
      ],
      release: import.meta.env.VITE_REACT_APP_SENTRY_RELEASE,
      tracesSampleRate: 1.0,
      autoSessionTracking: true,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.2,
      ignoreErrors: [/ResizeObserver/i]
    })
  }
}

type UserScope = {
  clientId: string
  fullName: string
  email: string
  phoneNumber: string
}

export function setMonitoringUserScope(user: UserScope): void {
  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: user.clientId,
      email: user.email,
      username: user.email
    })
  })
}

export function clearMonitoringUserScope(): void {
  Sentry.setUser(null)
}

type CollectErrorOptions = {
  context?: { name: string; values: Record<string, any> }
}

export function collectError(
  error: Error,
  options?: CollectErrorOptions
): void {
  if (isLocal()) {
    return
  }

  Sentry.captureException(error, (scope) => {
    if (options?.context) {
      scope.setContext(options.context.name, options.context.values)
    }
    return scope
  })
}

export const sentryReduxEnhancer = Sentry.createReduxEnhancer()
