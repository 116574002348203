import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from 'ts/types/Client'

import {
  ClientStatusEnum,
  CreditTypeEnum
} from '../../__generated__/globalTypes'
import { RootState } from '../../redux/slices'
import { ClientContract } from '../../ts/types/Contract'

type SetCurrentContract = {
  contract: ClientContract
}

type State = {
  newClientId: null | string
  loading: boolean
  error: boolean
  editModal: boolean
  contractModal: boolean
  currentClient: Client | null
  currentContract: ClientContract | null
  viewOnlyClientModal: boolean
  searchTerm: string
  statusFilter: string
  deleteContractModal: boolean
  clientSelectedTab: number
  showClientResetPassword: boolean
  uploadContractDocumentModal: boolean
  clientEmail: string
  showDeleteClientModal: boolean
  isClientDeleted: boolean
  isClientContractUpdated: boolean
  showClientBlacklistConfirmation: boolean
  isClientRestricted: boolean
}

export const initialState: State = {
  newClientId: null,
  loading: false,
  error: false,
  editModal: false,
  currentClient: null,
  contractModal: false,
  currentContract: null,
  viewOnlyClientModal: false,
  searchTerm: '',
  statusFilter: ClientStatusEnum.ACTIVE,
  deleteContractModal: false,
  clientSelectedTab: 0,
  showClientResetPassword: false,
  uploadContractDocumentModal: false,
  clientEmail: '',
  showDeleteClientModal: false,
  isClientDeleted: false,
  isClientContractUpdated: false,
  showClientBlacklistConfirmation: false,
  isClientRestricted: false
}

type SearchClient = {
  term: string
}

type FilterStatus = {
  status: string
}

type ClientPayload = {
  client: Client | null
}

type NewClientPayload = {
  clientId: string
}

const clientsSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    errors(state) {
      state.error = true
    },
    setClient(state, action: PayloadAction<ClientPayload>) {
      const { client } = action.payload
      state.currentClient = client

      if (state.currentContract && client?.contracts?.length) {
        // when delete or terminate packages
        const { id: currentContractId } = state.currentContract
        state.currentContract =
          client?.contracts.find(({ id }) => id === currentContractId) || null
      }
    },
    clearCurrentClient(state) {
      state.currentClient = null
    },
    setNewClientId(state, action: PayloadAction<NewClientPayload>) {
      const { clientId } = action.payload
      state.newClientId = clientId
    },
    searchClient(state, action: PayloadAction<SearchClient>) {
      const { term } = action.payload
      state.searchTerm = term
    },
    filterStatus(state, action: PayloadAction<FilterStatus>) {
      const { status } = action.payload
      state.statusFilter = status
    },
    showEditModal(state) {
      state.editModal = true
    },
    hideEditModal(state) {
      state.editModal = false
    },
    setCurrentContract(state, action: PayloadAction<SetCurrentContract>) {
      state.currentContract = action.payload.contract
    },
    clearCurrentContract(state) {
      state.currentContract = null
    },
    setViewOnlyClientModal(state) {
      state.viewOnlyClientModal = true
    },
    clearViewOnlyClientModal(state) {
      state.viewOnlyClientModal = false
    },
    showContractModal(state) {
      state.contractModal = true
    },
    hideContractModal(state) {
      state.contractModal = false
    },
    showDeleteContractModal(state) {
      state.deleteContractModal = true
    },
    hideDeleteContractModal(state) {
      state.deleteContractModal = false
    },
    setClientSelectedTab(
      state,
      {
        payload: { clientSelectedTab }
      }: { payload: { clientSelectedTab: number } }
    ) {
      state.clientSelectedTab = clientSelectedTab
    },
    setShowClientResetPassword(
      state,
      action: PayloadAction<{
        showClientResetPassword: boolean
        clientEmail: string
      }>
    ) {
      state.showClientResetPassword = action.payload.showClientResetPassword
      state.clientEmail = action.payload.clientEmail
    },
    showUploadContractDocumentModal(state) {
      state.uploadContractDocumentModal = true
    },
    hideUploadContractDocumentModal(state) {
      state.uploadContractDocumentModal = false
    },
    setShowDeleteClientModal(
      state,
      action: PayloadAction<{
        showDeleteClientModal: boolean
        isClientDeleted: boolean
      }>
    ) {
      state.showDeleteClientModal = action.payload.showDeleteClientModal
      state.isClientDeleted = action.payload.isClientDeleted
    },
    setIsContractUpdated(
      state,
      action: PayloadAction<{ isClientContractUpdated: boolean }>
    ) {
      state.isClientContractUpdated = action.payload.isClientContractUpdated
    },
    setShowClientBlacklistConfirmation(state, action: PayloadAction<{ showConfirmation: boolean, isClientRestricted: boolean }>) {
      state.showClientBlacklistConfirmation = action.payload.showConfirmation
      state.isClientRestricted = action.payload.isClientRestricted
    }
  }
})

export const loadingSelector = (state: RootState) =>
  state.clientsReducer.loading

export const errorsSelector = (state: RootState) => state.clientsReducer.error

export const currentClientSelector = (state: RootState) =>
  state.clientsReducer.currentClient

export const clientAccountCreditSelector = createSelector(
  (state: RootState) => state.clientsReducer.currentClient,
  (client) => {
    return client?.creditAccounts.find(
      (creditAccount) => creditAccount.creditType === CreditTypeEnum.GENERAL
    )
  }
)

export const editClientModalSelector = (state: RootState) =>
  state.clientsReducer.editModal

export const newClientIdSelector = (state: RootState) =>
  state.clientsReducer.newClientId

export const currentContractSelector = (state: RootState) =>
  state.clientsReducer.currentContract

export const currentContractIdSelector = createSelector(
  currentContractSelector,
  (contract) => contract?.id
)

export const viewOnlyClientModalSelector = (state: RootState) =>
  state.clientsReducer.viewOnlyClientModal

export const searchTermSelector = (state: RootState) =>
  state.clientsReducer.searchTerm

export const statusFilterSelector = (state: RootState) =>
  state.clientsReducer.statusFilter

export const contractModalSelector = (state: RootState) =>
  state.clientsReducer.contractModal

export const chooseDeleteModalSelector = (state: RootState) =>
  state.clientsReducer.deleteContractModal

export const clientSelectedTabSelector = (state: RootState) =>
  state.clientsReducer.clientSelectedTab

export const showClientResetPasswordSelector = (state: RootState): boolean =>
  state.clientsReducer.showClientResetPassword

export const clientEmailSelector = (state: RootState): string =>
  state.clientsReducer.clientEmail

export const uploadContractDocumentModalSelector = (state: RootState) =>
  state.clientsReducer.uploadContractDocumentModal

export const showDeleteClientModalSelector = (state: RootState): boolean =>
  state.clientsReducer.showDeleteClientModal

export const isClientDeletedSelector = (state: RootState): boolean =>
  state.clientsReducer.isClientDeleted

export const isClientContractUpdatedSelector = (state: RootState): boolean =>
  state.clientsReducer.isClientContractUpdated

export const showClientBlacklistConfirmationSelector = (state: RootState): boolean => state.clientsReducer.showClientBlacklistConfirmation

export const isClientRestrictedSelector = (state: RootState): boolean => state.clientsReducer.isClientRestricted

const { name, actions, reducer } = clientsSlice
export { name, actions, reducer, actions as clientsActions }
