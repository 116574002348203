import Box from '@luce/ui-kit/components/base/Box'
import IconButton from '@luce/ui-kit/components/button/IconButton'
import { MenuButton } from 'luce-ui-components'
import SideNav from '@luce/ui-kit/components/molecules/SideNav'
import { Theme, makeStyles } from '@luce/ui-kit/themes/ThemeProvider'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: '1200 !important' as any,
    '& .MuiBackdrop-root': {
      background: 'transparent'
    },
    '& .MuiDrawer-paperAnchorLeft': {
      paddingTop: theme.spacing(10)
    }
  },
  icon: {
    color: theme.palette.common.white
  }
}))

export type ActivePageRoute =
  | 'weekly'
  | 'daily'
  | 'clients'
  | 'workers'
  | 'visits'
  | 'payroll'
  | 'invoices'
  | 'bank-statements-cleaner'
  | 'daily-visit-assignment'
  | 'job-assignment'
  | 'workers-resignation'
  | 'worker-attendances'
  | 'worker-leaves'
  | 'worker-tip-message'
  | 'rewards'
  | 'promo-codes'
  | 'promo-management'
  | 'auth/access-control'
  | 'reports/projected-payrolls'
  | 'reports/new-recurring-clients'
  | 'test/reschedule-client-visit'
  | 'package-requests'
  | 'test/booking/home'
  | 'test/booking/aircon'
  | 'notifications'
  | 'sales-agent-commission'

interface Props {
  onLogout(): void
  activePageRoute: ActivePageRoute
}


const MenusNav: MenuButton[] = [
  {
    icon: 'CalendarIcon',
    label: 'Schedule',
    isExpand: true,
    subMenu: [
      {
        label: 'Weekly',
        url: 'weekly'
      },
      {
        label: 'Daily',
        url: 'daily'
      }
    ]
  },
  {
    icon: 'PeopleIcon',
    label: 'Clients',
    url: 'clients',
    isExpand: false
  },
  {
    icon: 'FreshIcon',
    label: 'Workers',
    url: 'workers',
    isExpand: false
  },
  {
    icon: 'AddressIcon',
    label: 'Visits',
    url: 'visits',
    isExpand: false
  },
  {
    icon: 'PaymentIcon',
    label: 'Payroll',
    url: 'payroll',
    isExpand: false
  },
  {
    icon: 'InvoiceIcon',
    label: 'Invoices',
    url: 'invoices',
    isExpand: false
  },
  {
    icon: 'ReceiptIcon',
    label: 'Bank Statements Cleaner',
    url: 'bank-statements-cleaner',
    isExpand: false
  },
  {
    icon: 'CalendarAssignIcon',
    label: 'Daily Visit Assignment',
    url: 'daily-visit-assignment',
    isExpand: false
  },
  {
    icon: 'PeofileCircleMinusIcon',
    label: 'Job Assignment',
    url: 'job-assignment',
    isExpand: false
  },
  {
    icon: 'OnefaceIcon',
    label: 'Workers Resignation',
    url: 'workers-resignation',
    isExpand: false
  },
  {
    icon: 'WorkerAttendances',
    label: 'Worker Attendances',
    url: 'worker-attendances',
    isExpand: false
  },
  {
    icon: 'CalendarOffIcon',
    label: 'Worker Leaves',
    url: 'worker-leaves',
    isExpand: false
  },
  {
    icon: 'CommentIcon',
    label: 'Worker Tip Message',
    url: 'worker-tip-message',
    isExpand: false
  },
  {
    icon: 'GiftIcon',
    label: 'Reward',
    url: 'rewards',
    isExpand: false
  },
  {
    icon: 'CouponIcon',
    label: 'Promo Codes',
    url: 'promo-codes',
    isExpand: false
  },
  {
    icon: 'PlusIcon',
    label: 'Promo Management',
    url: 'promo-management',
    isExpand: false
  },
  {
    icon: 'OnefaceIcon',
    label: 'Access Control',
    url: 'auth/access-control',
    isExpand: false
  },
  {
    icon: 'BlogIcon',
    label: 'Reports',
    isExpand: true,
    subMenu: [
      {
        label: 'New Recurring Clients',
        url: 'reports/new-recurring-clients'
      },
      {
        label: 'Projected Payrolls',
        url: 'reports/projected-payrolls'
      }
    ]
  },
  {
    icon: 'CalendarAssignIcon',
    label: 'Reschedule Client Visit (Beta)',
    url: 'test/reschedule-client-visit',
    isExpand: false
  },
  {
    icon: 'PlansIcon',
    label: 'Package Requests',
    url: 'package-requests',
    isExpand: false
  },
  {
    icon: 'AllServicesIcon',
    label: 'Booking Schedule (Beta)',
    isExpand: true,
    subMenu: [
      {
        label: 'Home Cleaning',
        url: 'test/booking/home'
      },
      {
        label: 'Aircon',
        url: 'test/booking/aircon'
      }
    ]
  },
  {
    icon: 'NotificationIcon',
    label: 'Notifications',
    url: 'notifications',
    isExpand: false
  },
  {
    icon: 'OnetimeIcon',
    label: 'Sales Agent Commission',
    url: 'sales-agent-commission',
    isExpand: false
  }
]

const NavigationDrawer: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const { onLogout, activePageRoute } = props
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false)

  const toggleDrawer = (open: boolean): void => {
    setIsNavbarOpen(open)
  }

  const handleLogout = (): void => {
    setIsNavbarOpen(false)
    onLogout()
  }

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.preventDefault()
  }

  const handleNavigation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url?: string,
    expand?: boolean
  ): void => {
    event.preventDefault()

    if (expand && !url) {
      return
    }

    history.push(`/${url}`)
    toggleDrawer(false)
  }

  return (
    <Box>
      <SideNav
        menus={MenusNav}
        isOpen={isNavbarOpen}
        toggleClick={() => toggleDrawer(false)}
        onClick={handleClickMenu}
        className={classes.root}
        activeRoute={activePageRoute}
        logOut={handleLogout}
        urlOnClick={handleNavigation}
      >
        {isNavbarOpen ? (
          <IconButton
            className={classes.icon}
            iconName="CloseIcon"
            onClick={() => toggleDrawer(false)}
          />
        ) : (
          <IconButton
            className={classes.icon}
            iconName="MenuIcon"
            onClick={() => toggleDrawer(true)}
          />
        )}
      </SideNav>
    </Box>
  )
}

export default NavigationDrawer
