import { yupResolver } from '@hookform/resolvers/yup'
import BoxRow from '@luce/ui-kit/components/atom/BoxRow'
import { Alert } from '@luce/ui-kit/components/atom/Lab'
import Box from '@luce/ui-kit/components/base/Box'
import Button from '@luce/ui-kit/components/button'
import { FormTextField, FormSelect, SelectOptions } from 'luce-ui-components'
import Dialog from '@luce/ui-kit/components/organism/Dialog'
import { Theme, makeStyles } from '@luce/ui-kit/themes/ThemeProvider'
import {
  NationalityEnum,
  WorkerDepartmentEnum
} from '__generated__/globalTypes'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { WorkerTipList } from 'ts/types/WorkerTips'
import { getErrorMessage } from 'utils/helpers'
import * as Yup from 'yup'

import { departmentOptions } from 'components/contract/form/lib'

import { FormStatus, WorkerTipsValues } from '../WorkerTip.screen'
import { useUpdateWorkerTip } from '../gqlhooks'
import { useCreateWorkerTip } from '../gqlhooks/useCreateWorkerTipsMutation'

interface Props {
  onSuccess?(): void
  workerTipModal: boolean
  onCloseModal(): void
  nationalityOptions: SelectOptions
  refetchWorkerTip(): void
  workerTip: WorkerTipList | null
}
export const WorkerTipFormSchema = Yup.object().shape({
  title: Yup.string().required('Worker Tip is a required field'),
  message: Yup.string().required('Worker Tip is a required field')
})

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginBottom: theme.spacing(2)
  },
  actionButton: {
    width: 144
  },
  avatar: {
    width: 128,
    height: 128
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  zeroSpacingMargin: {
    marginBottom: 0
  },
  formPhone: {
    '& .MuiOutlinedInput-root': {
      marginBottom: 0
    }
  },
  selectStatus: {
    width: '100%'
  },
  buttonAdd: {
    width: '100%'
  },
  formContainer: {
    textAlign: 'center'
  }
}))

const WorkerTipFormModal: React.FC<Props> = (props) => {
  const {
    nationalityOptions,
    onCloseModal,
    refetchWorkerTip,
    workerTip,
    workerTipModal
  } = props
  const classes = useStyles()
  const [createWorkerTip] = useCreateWorkerTip()
  const [updateWorkerTip] = useUpdateWorkerTip()
  const [status, setStatus] = useState<FormStatus>({})
  const { enqueueSnackbar } = useSnackbar()

  const initialValues: WorkerTipsValues = {
    id: workerTip?.id ?? '',
    message: workerTip?.message ?? '',
    title: workerTip?.title ?? '',
    nationality: workerTip?.nationality ?? NationalityEnum.Singaporean,
    department: workerTip?.department ?? WorkerDepartmentEnum.HOME_CLEANING
  }

  const methods = useForm<WorkerTipsValues>({
    mode: 'onSubmit',
    resolver: yupResolver(WorkerTipFormSchema),
    defaultValues: initialValues
  })

  const { reset, handleSubmit } = methods

  const isFormEdit = workerTip?.id

  useEffect(() => {
    if (workerTip) {
      reset(workerTip)
    } else if (!isFormEdit) {
      reset(initialValues)
    }
  }, [workerTip, isFormEdit])

  const onSubmit = async (values: WorkerTipsValues) => {
    try {
      if (isFormEdit) {
        await handleUpdateWorkerTip(values)
      } else {
        await handleCreateWorkerTip(values)
      }
      enqueueSnackbar('Worker tip message saved', { variant: 'success' })
      setStatus({ success: true })
      refetchWorkerTip()
      onCloseModal()
    } catch (err) {
      const errorMsg = getErrorMessage(err, err.toString() || 'Failed to save')
      setStatus({ success: false, error: errorMsg })
    }
  }

  const handleCreateWorkerTip = (values: WorkerTipsValues) => {
    return createWorkerTip({
      title: values.title,
      nationality: values.nationality,
      department: values.department,
      message: values.message
    })
  }

  const handleUpdateWorkerTip = (values: WorkerTipsValues) => {
    if (!workerTip?.id) {
      return
    }

    return updateWorkerTip({
      id: workerTip.id,
      nationality: values.nationality,
      department: values.department,
      message: values.message,
      title: values.title
    })
  }

  if (workerTipModal) {
    return (
      <Dialog
        open
        onClose={onCloseModal}
        fullWidth
        maxWidth="xs"
        title={
          isFormEdit ? 'Edit Worker Tip Message' : 'Add Worker Tip Message'
        }
        className={classes.formContainer}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              {status?.error && !status.success && (
                <Box mb={2}>
                  <Alert severity="error">{status.error}</Alert>
                </Box>
              )}
            </Box>
            <Box textAlign="left">
              <Box mt={4}>
                <FormTextField fullWidth name="title" label="Message Title" />
              </Box>
              <Box>
                <FormSelect
                  fullWidth
                  name="nationality"
                  label="Nationality"
                  options={nationalityOptions}
                  customSize="large"
                />
              </Box>
              <Box>
                <FormSelect
                  fullWidth
                  name="department"
                  label="Department"
                  options={departmentOptions()}
                  customSize="large"
                />
              </Box>
              <Box>
                <FormTextField
                  multiline
                  name="message"
                  label="Message Body"
                  customSize="large"
                  placeholder="Type the message here"
                />
              </Box>
              <BoxRow gap={2} justifyContent="center">
                <Button
                  className={classes.buttonAdd}
                  variants="primary"
                  customSize="large"
                  type="submit"
                >
                  Add Worker Tip
                </Button>
              </BoxRow>
            </Box>
          </form>
        </FormProvider>
      </Dialog>
    )
  }
  return null
}

export default WorkerTipFormModal
